import { RECEIVE_LOCATION } from './actions';

const getLocationMap = (locationData) => {
  // the raw response from location service wraps the data in _result
  return {
    [`${locationData._result._entity_id}`.toUpperCase()]: locationData._result
  };
};

export default function locationReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_LOCATION:
      return Object.assign({}, state, getLocationMap(action.payload));
    default:
      return state;
  }
}
