import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  firstName: {
    id: 'patient.error.firstName',
    description: 'First Name is missing from patient',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'patient.error.lastName',
    description: 'Last Name is missing from patient',
    defaultMessage: 'Last Name'
  },
  email: {
    id: 'patient.error.email',
    description: 'Email is missing from patient',
    defaultMessage: 'Email'
  },
  dateOfBirth: {
    id: 'patient.error.dateOfBirth',
    description: 'Date Of Birth is missing from patient',
    defaultMessage: 'Date Of Birth'
  },
  gender: {
    id: 'patient.error.gender',
    description: 'Gender is missing from patient',
    defaultMessage: 'Gender'
  },
  phoneNumber: {
    id: 'patient.error.phoneNumber',
    description: 'Phone Number is missing from patient',
    defaultMessage: 'Phone Number'
  },
  address: {
    id: 'patient.error.address',
    description: 'Address is missing from patient',
    defaultMessage: 'Address'
  },
  street1: {
    id: 'patient.error.street1',
    description: 'Street 1 is missing from patient address',
    defaultMessage: 'Street 1'
  },
  city: {
    id: 'patient.error.city',
    description: 'City is missing from patient address',
    defaultMessage: 'City'
  },
  zip: {
    id: 'patient.error.zip',
    description: 'ZIP is missing from patient address',
    defaultMessage: 'Zip'
  },
  state: {
    id: 'patient.error.state',
    description: 'State is missing from patient address',
    defaultMessage: 'State'
  },
  errorBooking: {
    id: 'patient.error.errorBooking',
    description:
      'Message displayed to the user when missing patient data required for booking.',
    defaultMessage:
      'The following information is required for booking and missing from the patient. Please update the patient record to continue scheduling.'
  },
  errorReferral: {
    id: 'patient.error.errorReferral',
    description:
      'Message displayed to the user when missing patient data required for referral.',
    defaultMessage:
      'The following information is required for referral and missing from the patient. Please update the patient record to continue.'
  }
});
