import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const PoweredByLogoWrapper = styled('div')`
  display: flex;
  justify-content: center;
  // this padding makes the rebranded logo match the shape of the old logo
  // until we redesign the search footer
  padding-top: ${fromTheme('spacing_small')};

  @media (min-width: ${fromTheme('screen_medium')}) {
    justify-content: flex-end;
  }
`;
