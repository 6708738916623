import querystring from 'querystring';
import { STICKY_QUERY_PARAMS } from './constants';
import { getDefaultCustomerSort } from './search-common';

/**
 * Given a location from react-router or window, return an object of query params
 * that exist on the location that should be sticky and carried across to other urls
 * @param {} location
 * @returns {object} Object of paramName => paramValue pairs
 */
export function getStickyParamsFromLocation(location) {
  if (!location?.search || location.search === '?') {
    return {};
  }
  const params = {};
  const parsedParams = querystring.parse(location.search.substring(1));
  STICKY_QUERY_PARAMS.forEach((param) => {
    if (param in parsedParams) {
      params[param] = parsedParams[param];
    }
  });
  return params;
}

/**
 * Append a param and paramValue to the query of a given url or path. This method
 * does not check if the param already exists on the provided url
 * @param {string} stringUrlOrPath
 * @param {*} param
 * @param {*} paramValue
 * @returns {string} new string with param appended
 */
export function withQueryParam(stringUrlOrPath, param, paramValue) {
  // if path is missing, don't concatenate anything
  if (stringUrlOrPath == null) {
    return stringUrlOrPath;
  }
  // don't append undefined values
  if (typeof paramValue === 'undefined') {
    return stringUrlOrPath;
  }
  const prefix = stringUrlOrPath.indexOf('?') > -1 ? '&' : '?';
  return `${stringUrlOrPath}${prefix}${param}=${encodeURIComponent(
    paramValue
  )}`;
}

/**
 * Get the base url from the request object for server side requests
 *
 * TODO: Remove this when v8 is retired
 *
 * @param {object} req
 * @returns {string} an empty string for client side requests, and a valid url for SSR
 */
export async function getBaseUrlFromRequest(req) {
  if (req == null || req.getAppSettings == null) return '';
  const appSettings = await req.getAppSettings();

  return appSettings.PMC_NODE_SERVER_URL;
}

/**
 * Given a location from window, and Login Service URL from app settings
 * build the url to redirect user to login page then back to PMC on successful login
 * @param {object} location
 * @param {string} loginServiceUrl
 * @returns {string}
 */
export function getLoginRedirectUrl(location, loginServiceUrl) {
  const baseUrl = `https://${location.host}${location.pathname}`;
  const params = new URLSearchParams();
  params.append('next', baseUrl);
  const queryString = params.toString();
  return `${loginServiceUrl}/login?${queryString}`;
}

/**
 * Given a customer config, returns the url to reset a search.
 * Customers with "search_home" configured reset with a null search.
 * "search_home" can be "/search" with no filters
 * or include filters example: "/search?filter=provider.accepting_new_patients%3Atrue"
 * @param {object} config
 * @returns {string} url
 */
export function getStartNewSearchUrl(config) {
  const searchHome = config?.search_home;
  if (searchHome) {
    const defaultSort = getDefaultCustomerSort(config);
    let prefix = '?';
    if (searchHome.includes('?')) {
      prefix = '&';
    }
    return `${searchHome}${prefix}sort=${encodeURIComponent(defaultSort)}`;
  }
  return '/';
}

/**
 * Returns the url to find providers at a given marketable location
 * @param {string} locationId
 * @param {object} history
 * @param {string} defaultSort
 * @returns {string} url
 */
export function getProvidersAtLocationUrl(locationId, history, defaultSort) {
  const mLocIdQuery = {
    filter: [
      `locations.associated_marketable_location_ids:${locationId.toUpperCase()}`
    ],
    sort: defaultSort,
    ...getStickyParamsFromLocation(history.location)
  };
  const queryString = querystring.stringify(mLocIdQuery);
  return `/search?${queryString}`;
}
