import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import { ButtonWithSpinner } from '@kyruus/button';

export const CurrentPositionBtn = styled(ButtonWithSpinner)`
  color: ${fromTheme('color_link')};
  font-size: ${fromTheme('font_size_small')};
  margin-top: ${fromTheme('spacing_small')};
`;

export const ScreenReaderAriaLabel = styled('label')`
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  opacity: 0;
  z-index: -1;
`;

export const LocationFacetBody = styled('div')`
  grid-area: facetbody;
  padding: 0 ${fromTheme('spacing_medium')};
  margin: ${fromTheme('spacing_small')} 0 ${fromTheme('spacing_small')};
`;
