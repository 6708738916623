import React from 'react';
import { InvalidFieldList, MessageWrapper } from './styles';
import { FormattedMessage } from '@kyruus/intl';
import { messages } from './messages';

export const InvalidPatientDataMessage = ({
  type,
  invalidFields = [],
  color
}) => {
  return (
    <MessageWrapper>
      <FormattedMessage {...messages[type]} />
      {invalidFields.length > 0 ? (
        <InvalidFieldList color={color}>
          {invalidFields.map((field) => {
            return (
              <FormattedMessage key={field} tagName="li" {...messages[field]} />
            );
          })}
        </InvalidFieldList>
      ) : null}
    </MessageWrapper>
  );
};
