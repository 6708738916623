import { logSentryError } from '../../utils/logSentryError';

/** @summary Returns human-readable formatted address of best result returned from AWS Location Service Places Index.
 */
const getHrfAddress = async ({ lat, lng }) => {
  try {
    const response = await fetch(`/aws-maps/reverse-geocode/${lng}/${lat}`);
    const { Results } = await response.json();
    return Results[0].Place.Label;
  } catch (e) {
    logSentryError(e);
  }
};

export default getHrfAddress;
