import type { Request } from '../types/express';

type BaseCookieParams = {
  httpOnly: boolean;
  secure: boolean;
  sameSite: 'None' | 'Strict' | 'Lax';
  partitioned: boolean;
};

export async function getBaseCookieParams(
  req: Request
): Promise<BaseCookieParams> {
  const appSettings = await req.getAppSettings();
  return {
    // needs to be readable client-side
    httpOnly: false,
    secure: appSettings['USE_SECURE_TRACKING_TOKEN_COOKIE'] as boolean,
    sameSite: 'None',
    // partitioned cookie scheme is required for the session cookies to work in an iframed environment
    partitioned: true
  };
}
