/**
 * Utility function that will wrap the given function call and cache its value
 * so that it is only run at most once per request
 * @param {Object} { req } http request object
 * @param {*} func function to execute to get the value if value is not cached
 * @returns {func} memo-ized function
 */
function functionCachedPerRequest({ req }, func) {
  if (typeof func !== 'function') {
    throw new Error('Missing function');
  }
  /**
   * The value of the key isn't important here, only that
   * it is unique for every function wrapped
   */
  req._cachedFuncsKey = req._cachedFuncsKey || 0;
  const key = `${req._cachedFuncsKey++}`;
  return async () => {
    req._cachedFuncs = req._cachedFuncs || {};
    if (!(key in req._cachedFuncs)) {
      req._cachedFuncs[key] = await func(req);
    }
    return req._cachedFuncs[key];
  };
}

export { functionCachedPerRequest };
