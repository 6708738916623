/** like js .split() but includes any secondary delimiters
 * for example:
 * "a:b:c".split(':', 2) => ["a", "b"]
 * splitOnce("a:b:c", ':') => ["a", "b:c"]
 */
export function splitOnce(string, delimiter) {
  const [first, ...rest] = string.split(delimiter);
  if (rest && rest.length > 0) {
    return [first, rest.join(delimiter)];
  }
  return [first];
}
