export function searchSelector(state = {}) {
  return state.search || {};
}

export function getProviderIds(state = {}) {
  return searchSelector(state).providerIds || [];
}

export function querySelector(state = {}) {
  return searchSelector(state).query;
}
