import moment from 'moment';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import { COOKIE_NAMES, QUERY_PARAMS } from 'Common/logging/constants';
import { splitOnce } from 'Common/utils/splitOnce';
import { SEARCH_ID_TOKEN_DELIMITER } from 'Common/logging/search-id';

import type { Request } from '../types/express';

export const TIMESTAMP_PARAM_NAME = 'timestamp';

type TokenDefinition = {
  // what param to look on the query for the value
  queryParamName: string;
  // if true, only accept the query param if the current timestamp is within
  // some window of the one passed on the query
  checkQueryTimestamp: boolean;
  // what cookie to look for the value
  cookieName: string | null;
  // if true, generate a new UUID token if none is found
  generateByDefault: boolean;
  // function to validate the token, we'll only read it from param or cookies
  // if this function returns true
  validateFunc: (value: string) => boolean;
};

// validate a search_id token
export function isSearchIdTokenValid(token: string): boolean {
  try {
    const [uuid] = splitOnce(token, SEARCH_ID_TOKEN_DELIMITER);
    // validate hash here? or is it enough to just validate the uuid?
    return uuidValidate(uuid);
  } catch (e) {
    return false;
  }
}

// generic uuid validation
export function isUuidValid(uuid: string): boolean {
  return uuidValidate(uuid);
}

export function generateToken(): string {
  return uuidv4();
}

export async function timestampIsValid(req: Request): Promise<boolean> {
  const appSettings = await req.getAppSettings();
  const timestamp = req.query[TIMESTAMP_PARAM_NAME];

  /*
   * Checks if the timestamp is correctly formatted
   * and within the expected range
   */
  if (!timestamp) {
    return false;
  }
  // 'x' indicates we expect a unix timestamp
  const timestampMoment = moment(timestamp as string, 'x');
  if (!timestampMoment.isValid()) {
    return false;
  }
  const now = moment();
  const allowedPastMinutes =
    appSettings['TRACKING_TOKEN_URL_TIMESTAMP_ALLOWED_PAST_MINUTES'];
  const allowedFutureMinutes =
    appSettings['TRACKING_TOKEN_URL_TIMESTAMP_ALLOWED_FUTURE_MINUTES'];
  const earliestTime = now.clone().subtract(allowedPastMinutes, 'm');
  const latestTime = now.clone().add(allowedFutureMinutes, 'm');
  const isBetween = timestampMoment.isBetween(earliestTime, latestTime);
  return isBetween;
}

export function getFromQuery(
  req: Request,
  tokenDefinition: TokenDefinition
): string | null {
  const { queryParamName, validateFunc } = tokenDefinition;
  if (queryParamName) {
    const token = req.query[queryParamName] || null;
    if (token && validateFunc(token)) {
      return token;
    }
  }
  return null;
}

export function getFromCookies(
  req: Request,
  tokenDefinition: TokenDefinition
): string | null {
  const { cookieName, validateFunc } = tokenDefinition;
  if (cookieName) {
    const token = req.cookies[cookieName];
    if (token && validateFunc(token)) {
      return token;
    }
  }
  return null;
}

/*
 * 1. If token is a query param in the URL, use that
 * 2. If token is set in a cookie, use that
 * 3. Otherwise, create a new token and return it
 * Note: This method does not set the token in a cookie.
 */
export async function getToken(
  req: Request,
  tokenDefinition: TokenDefinition
): Promise<string | null> {
  const {
    // query param to read the token's value from
    queryParamName = null,
    // name of the cookie to read the value
    cookieName = null,
    // only return token from the url if the timestamp param is valid
    checkQueryTimestamp = false,
    // if the cookie isn't in query params or cookies, do we want to assign a random new value?
    generateByDefault = false
  } = tokenDefinition;

  if (queryParamName) {
    const queryValue = getFromQuery(req, tokenDefinition);
    if (queryValue) {
      if (checkQueryTimestamp) {
        if (await timestampIsValid(req)) {
          return queryValue;
        }
        // if timestamp is invalid, we won't return the value from query
        // and instead fall through to cookie check
      } else {
        return queryValue;
      }
    }
  }

  if (cookieName) {
    const cookieValue = getFromCookies(req, tokenDefinition);
    if (cookieValue) {
      return cookieValue;
    }
  }

  if (generateByDefault) {
    return generateToken();
  }
  return null;
}

/**
 * Meant for tying all events in a single user visit together,
 * but not for long-term tracking
 */
export const CONSUMER_TRACKING_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.CONSUMER_TRACKING_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.CONSUMER_TRACKING_TOKEN,
  generateByDefault: true,
  validateFunc: isUuidValid
};

/**
 * Ties api requests during the same SSR render and api calls together,
 * Also carries over to client side tracking directly on the rendered page
 * and pages navigated to via history push in the client app.
 *
 * Kind of broken for its original purpose, since it isn't cookied and is lost
 * during non-push-state transitions from the SERP to the profile page. Should be
 * considered deprecated in favor of SEARCH_ID_TOKEN
 */
export const SEARCH_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_TOKEN,
  checkQueryTimestamp: false,
  cookieName: null, // search token does not get added to nor read from cookies
  generateByDefault: true,
  validateFunc: isUuidValid
};

/**
 * Meant as a long-lived user tracking token, for tracking
 * repeat users and has a long expiration time when cookied
 */
export const USER_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.USER_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.USER_TOKEN,
  generateByDefault: true,
  validateFunc: isUuidValid
};

/**
 * Stores the shuffle token value passed to the search api, to make sure the order of
 * providers is stable between page reloads and pagination between pages
 */
export const SEARCH_SHUFFLE_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_SHUFFLE_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.SEARCH_SHUFFLE_TOKEN,
  generateByDefault: true,
  validateFunc: isUuidValid
};

/**
 * The search ID token is used to track a user's search session.
 * It is regenerated when a user creates a new search via either search term
 * or location change, and then passed to subsequent tracking events
 *
 * If the user hasn't made a search during their session, the value will be `null`
 */
export const SEARCH_ID_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_ID_TOKEN, // as in kyruus search id, because `sid` is already used for the application's session id cookie
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.SEARCH_ID_TOKEN,
  generateByDefault: false,
  validateFunc: isSearchIdTokenValid
};
