import React from 'react';
import { CrmConnectorContext } from './useCrmConnector';
import { ReferralConnectorContext } from './useReferralConnector';
import { MODULES, isModuleEnabled } from 'Common/config';
/**
 * Connector to return CrmConnectorContext (which has referral functionality integrated) if `CRM_INTEGRATION` module is enabled
 * otherwise just the ReferralContext (which doesn't contain the CRM functionality)
 * @param {*} config
 * @returns
 */
export const useReferralCrmConnector = (config) => {
  const crmEnabled = isModuleEnabled(config, MODULES.CRM_INTEGRATION);
  const context = React.useContext(
    crmEnabled ? CrmConnectorContext : ReferralConnectorContext
  );
  if (context == null) {
    throw new Error(`Context must be used within a ConnectorProvider`);
  }

  return context;
};
