import React from 'react';
import Helmet from 'react-helmet';
import Select from 'react-select-plus';
import { injectIntl, FormattedMessage } from '@kyruus/intl';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

function OptionComponent({
  intl,
  messageDescriptor,
  messageDescriptorValues,
  ...props
}) {
  const label = intl.formatMessage(messageDescriptor, messageDescriptorValues);
  return (
    <option {...props} label={label}>
      {label}
    </option>
  );
}

function HelmetComponent({
  intl,
  titleMessageDescriptor,
  titleMessageDescriptorValues,
  metaMessageDescriptors,
  ...props
}) {
  const meta = _map(metaMessageDescriptors, (descriptor) => {
    descriptor.content = intl.formatMessage(
      descriptor.contentMessageDescriptor,
      descriptor.contentMessageDescriptorValues
    );
    return _omit(descriptor, [
      'contentMessageDescriptor',
      'contentMessageDescriptorValues'
    ]);
  });
  return (
    <Helmet
      {...props}
      meta={meta}
      title={intl.formatMessage(
        titleMessageDescriptor,
        titleMessageDescriptorValues
      )}
    />
  );
}

function ReactSelectComponent({
  intl,
  optionsMessageDescriptors,
  ariaMessageDescriptor,
  placeholderMessageDescriptor,
  placeholderMessageDescriptorValues,
  defaultPlaceholderMessageDescriptor,
  ...props
}) {
  const options = _map(optionsMessageDescriptors, (descriptor) => {
    if (descriptor.labelMessageDescriptor) {
      descriptor.label = intl.formatMessage(
        descriptor.labelMessageDescriptor,
        descriptor.labelMessageDescriptorValues
      );
    }
    if (descriptor.options) {
      descriptor.options = _map(descriptor.options, (option) => {
        option.label = intl.formatMessage(option.labelMessageDescriptor);
        return _omit(option, 'labelMessageDescriptor');
      });
    }
    return _omit(descriptor, [
      'labelMessageDescriptor',
      'labelMessageDescriptorValues'
    ]);
  });
  if (options.length) {
    props.options = options;
  }
  if (placeholderMessageDescriptor) {
    if (intl.messages[placeholderMessageDescriptor.id]) {
      props.placeholder = intl.formatMessage(
        placeholderMessageDescriptor,
        placeholderMessageDescriptorValues
      );
    } else {
      props.placeholder = intl.formatMessage(
        defaultPlaceholderMessageDescriptor
      );
    }
  }
  props['aria-label'] = intl.formatMessage(ariaMessageDescriptor);

  // set data-testid
  props.inputProps = { ...props.inputProps, 'data-testid': props.instanceId };

  return <Select {...props} />;
}

function FormattedEmptyDefaultMessageComponent({ intl, ...props }) {
  return _get(intl.messages, props.id) ? <FormattedMessage {...props} /> : null;
}

function makeInternational(Component) {
  const InternationalComponent = ({
    intl,
    intlProps,
    componentRef,
    ...props
  }) => {
    const translatedProps = _reduce(
      intlProps,
      (acc, description, prop) => {
        acc[prop] = intl.formatMessage(
          description.descriptor,
          description.values
        );
        return acc;
      },
      {}
    );
    return <Component {...translatedProps} ref={componentRef} {...props} />;
  };
  const componentName =
    typeof Component === 'string' ? Component : Component.displayName;
  InternationalComponent.displayName = `InternationalComponent(${componentName})`;
  return injectIntl(InternationalComponent);
}

export const IntlHelmet = injectIntl(HelmetComponent);
export const IntlReactSelect = injectIntl(ReactSelectComponent);
export const IntlOption = injectIntl(OptionComponent);

export const FormattedEmptyDefaultMessage = injectIntl(
  FormattedEmptyDefaultMessageComponent
);

export { makeInternational };
