export async function unwrapResponse(fetchPromise) {
  const response = await fetchPromise;

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const json = await response.json();

  return json;
}

export function convertFacetsToSlots(facetData, apptInfo) {
  const groupedSlots = {};
  for (const { terms: locTerms, value: providerId } of facetData[0].terms) {
    groupedSlots[providerId] = {
      apptInfo,
      slots: []
    };
    for (const { terms: dateTerms, value: location_id } of locTerms) {
      for (const { terms: startTerms, value: date } of dateTerms) {
        for (const { value: start } of startTerms) {
          const slot = {
            date,
            start,
            location_id,
            purpose: apptInfo.purpose,
            patient_relationship: apptInfo.relationship
          };
          groupedSlots[providerId].slots.push(slot);
        }
      }
    }
  }
  return groupedSlots;
}
