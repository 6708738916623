/**
 * Returns true if PMC is embedded in an iframe. Otherwise, returns false.
 */
function isEmbeddedInFrame() {
  // https://stackoverflow.com/a/326076
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export { isEmbeddedInFrame };
