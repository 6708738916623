/**
 * Given search params (object of key/values for search api) and an nlpResult from v9 search API, return a searchParam
 * that represents the original searchParams search with any params from the NLP result included
 * @param {Object} searchParams
 * @param {Object} nlpActions nlp actions from v9 search result
 */
function mergeSearchParamsAndNlpResult(searchParams, nlpActions) {
  try {
    let updatedSearchParams = { ...searchParams };
    if (nlpActions) {
      if (nlpActions.filter?.length) {
        const nlpFilters = nlpActions.filter;
        // combine NLP and searchParams
        updatedSearchParams = {
          ...updatedSearchParams,
          filter: [
            // merge searchParams.filter with nlpFilters, preserving only unique non-empty items
            ...new Set(
              // searchParams.filter is either an array or single string, so account for either case here
              [updatedSearchParams.filter, ...nlpFilters].filter(Boolean).flat()
            )
          ]
        };
        updatedSearchParams.unified = nlpActions.nlp_search;
      }
      if (nlpActions.location) {
        updatedSearchParams = {
          ...updatedSearchParams,
          display_location: nlpActions.location,
          location: nlpActions.location
        };
        updatedSearchParams.unified = nlpActions.nlp_search;
      }
      if (nlpActions.sort) {
        updatedSearchParams = {
          ...updatedSearchParams,
          sort: nlpActions.sort
        };
      }
    }
    return updatedSearchParams;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return searchParams;
  }
}

export { mergeSearchParamsAndNlpResult };
