/**
 * Returns request headers for server side requests
 *
 * @param {object} req
 * @returns {object} { actor, cookies }
 */
async function getRequestHeaders(req) {
  if (req == null || req.getActor == null) return;

  const actor = await req.getActor();
  return {
    actor,
    cookies: req.cookies
  };
}

export default getRequestHeaders;
