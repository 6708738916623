import https from 'https';

/**
 * For local development, return an https agent that disables certificate validation for SSR only
 *
 * @param {object} req
 * @returns Https Agent instance or undefined
 */
export default function (req) {
  if (
    Object.keys(https).length > 0 &&
    req != null &&
    req.protocol === 'https' &&
    req.getAppEnvironment != null &&
    req.getAppEnvironment() === 'development'
  ) {
    return new https.Agent({
      rejectUnauthorized: false
    });
  }
}
