import { SET_SEARCH_WIDGET_CONFIG } from './actions';

export default function searchWidgetConfigReducer(state = {}, action) {
  switch (action.type) {
    case SET_SEARCH_WIDGET_CONFIG: {
      return action.payload;
    }
    default:
      return state;
  }
}
