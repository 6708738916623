import { useState, useEffect } from 'react';
import { GENERIC_GEOLOCATION_ERROR } from '../utils/constants';

/** Returns { geolocation, error }
 *
 * @summary This is a custom React hook implementation of the Geolocation API.
 *
 * @description The Geolocation API allows the user to provide their location to web applications if they so desire.
 * For privacy reasons, the user is asked for permission to report location information.
 *
 * Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
 */
function useBrowserGeolocation(
  options = {
    maximumAge: 0,
    timeout: Infinity,
    enableHighAccuracy: false
  },
  /** Control when the browser requests the user's permission to share location information. */
  shouldExecute = true
) {
  const [geolocation, setGeolocation] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (shouldExecute) {
      setIsLoading(true);
      if (!window.navigator.geolocation) {
        setError(GENERIC_GEOLOCATION_ERROR);
      } else {
        window.navigator.geolocation.getCurrentPosition(
          (geolocation) => {
            setGeolocation(geolocation);
            setIsLoading(false);
          },
          setError,
          options
        );
      }
    }
  }, [shouldExecute]);

  return { geolocation, error, isLoading };
}

export default useBrowserGeolocation;
