// config value that determines product name and user type
export const PERSONAS = {
  CONSUMER: 'consumer',
  AGENT: 'agent',
  EMPLOYED_USER: 'employed_user'
};

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_SITE_TITLE = 'ProviderMatch';

export const MODULES = {
  SEARCH_MATCH: 'search_match',
  MAP_SEARCH: 'map_search',
  DIRECT_BOOK: 'direct_book',
  LOCATIONS: 'locations',
  CRM_INTEGRATION: 'crm_integration',
  AUTHENTICATION: 'authentication',
  PROFILE: 'profile',
  VISIBILITY_AGENT: 'visibility_agent',
  REFERRAL: 'referral'
};

export const CONFIG_ERROR = {
  UNSUPPORTED_KEY: 'UNSUPPORTED_KEY',
  UNSUPPORTED_MODULE: 'UNSUPPORTED_MODULE',
  INVALID_LOCALE: 'INVALID_LOCALE',
  CERNER_INTEGRATION_MISSING_DB_ROUTE_TO:
    'CERNER_INTEGRATION_MISSING_DB_ROUTE_TO'
};

// map APP_ENVIRONMENT to default tealium env to use for that app environment
export const TEALIUM_ENVS = {
  development: 'dev',
  staging: 'stg',
  production: 'prd',
  default: 'dev' // fallback for non-match
};

export const configFieldWhitelistClient = [
  'active_modules',
  'application_string_templates',
  'crm',
  'darkship_display_providers_on_locations_page',
  'darkship_feature_flags',
  'darkship_KENG_31725',
  'darkship_use_list_page_searchv9',
  'darkship_use_profile_searchv9',
  'datadog',
  'display_availability_in_search',
  'domains',
  'emergency_disclaimer',
  'emergency_disclaimer_md',
  'enable_read_only_availability',
  'facets',
  'facets_v9',
  'feature_flags',
  'google_configuration',
  'gxv2_enabled',
  'hide_availability_density',
  'host',
  'index',
  'insurance_disclaimer',
  'insurance_information_link_href',
  'KENG_38276_show_search_suggestions_v9',
  'locale',
  'locale_domains',
  'location_facet',
  'maps_config',
  'logging_metadata',
  'logos',
  'logos_v9',
  'minimum_appointment_purposes_for_filter',
  'modal_display',
  'og_image',
  'persona',
  'provider_tile',
  'provider',
  'provider_v9',
  'search_alerts',
  'search_home',
  'search_params',
  'search_results_page',
  'search_results_page_v9',
  'search_widget',
  'seo',
  'show_dbw_appt_time_alt_providers',
  'show_powered_by_kyruus_logo',
  'show_provider_profile_alt_providers',
  'skip_db_enablement_check',
  'social_share',
  'sort_options',
  'tealium',
  'theme',
  'third_party',
  'referral'
];
