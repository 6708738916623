import _startCase from 'lodash/startCase';
import { defineMessages } from '@kyruus/intl';

import {
  ENTITY_TYPE,
  LOCATION_ENTITY_TYPE,
  V8_PATIENT_REL_FILTER_KEY,
  V8_PURPOSE_FILTER_KEY,
  V8_VISIBILITIES_FILTER_BASE,
  V8_DIRECT_BOOK_CAPABLE_FILTER,
  ESTABLISHED_PATIENT,
  NEW_PATIENT
} from '../utils/constants';

import { splitOnce } from 'Common/utils/splitOnce';

const messages = defineMessages({
  primary_care: {
    id: 'search.searchtypes.primarycare',
    description:
      'Category name for a suggested search that is a primary care provider',
    defaultMessage: 'Primary Care'
  },
  primary_care_condition: {
    id: 'search.searchtypes.primarycare',
    description:
      'Category name for a suggested search that is a primary care provider',
    defaultMessage: 'Primary Care'
  },
  primary_care_condition_ranked: {
    id: 'search.searchtypes.primarycare',
    description:
      'Category name for a suggested search that is a primary care provider',
    defaultMessage: 'Primary Care'
  },
  clinical_experience: {
    id: 'search.searchtypes.clinicalexperience',
    description: 'Category name for a suggested search that is a condition',
    defaultMessage: 'Conditions'
  },
  clinical_combined: {
    id: 'search.searchtypes.clinicalcombined',
    description:
      'Category name for a suggested search is clinical term and/or specialty',
    defaultMessage: 'Clinical Combined'
  },
  specialty: {
    id: 'search.searchtypes.specialty',
    description: 'Category name for a suggested search that is a specialty',
    defaultMessage: 'Specialties'
  },
  specialty_strict: {
    id: 'search.searchtypes.specialty',
    description: 'Category name for a suggested search that is a specialty',
    defaultMessage: 'Specialties'
  },
  specialty_synonym: {
    id: 'search.searchtypes.specialty',
    description: 'Category name for a suggested search that is a specialty',
    defaultMessage: 'Specialties'
  },
  department: {
    id: 'search.searchtypes.department',
    description: 'Category name for a suggested search that is a department',
    defaultMessage: 'Departments'
  },
  practice_group: {
    id: 'search.searchtypes.department',
    description: 'Category name for a suggested search that is a department',
    defaultMessage: 'Departments'
  },
  name: {
    id: 'search.searchtypes.providername',
    description: 'Category name for a suggested search that is a provider name',
    defaultMessage: 'Providers'
  },
  network_affiliation: {
    id: 'search.searchtypes.networkaffiliation',
    description: 'Category name for a suggested search that is an affiliation',
    defaultMessage: 'Affiliations'
  }
});

function searchCategoryDisplayDescriptor(category) {
  return (
    messages[category] || {
      id: `search.searchtypes.${category}`,
      defaultMessage: _startCase(category)
    }
  );
}

/**
 * Given a provider and a visibility filter,
 * will return a list of purpose options by patientRel
 * @param {Object} provider
 * @param {string} visibility
 * @returns {Object} {new: Set(), established: Set()}
 */
function getPurposeOptionsByPatientRel(
  provider,
  visibility = 'consumer:direct_book'
) {
  const purposeByRel = {
    [NEW_PATIENT]: [],
    [ESTABLISHED_PATIENT]: []
  };
  const [visKey, visValue] = splitOnce(visibility, ':');
  const ehrPurposes = provider.appointment_ehr_purposes || [];

  ehrPurposes.forEach(({ ehr_data, name, patient_relationship }) => {
    const isVisible = ehr_data.find(({ visibilities }) => {
      return visibilities[visKey] === visValue;
    });

    if (
      isVisible &&
      [ESTABLISHED_PATIENT, NEW_PATIENT].includes(patient_relationship)
    ) {
      if (
        !purposeByRel[patient_relationship].find(
          (purpose) => purpose.name === name
        )
      ) {
        purposeByRel[patient_relationship].push({
          name,
          visit_method: isVisible.visit_method
        });
      }
    }
  });

  const sortByName = (purposes) =>
    purposes.sort((a, b) => a.name.localeCompare(b.name));

  const results = {
    new: sortByName(purposeByRel.new),
    established: sortByName(purposeByRel.established)
  };

  return results;
}

function splitObjectKeys(arr) {
  return arr.map((item) => {
    const [key, value] = splitOnce(item, ':');
    return {
      [key]: value
    };
  });
}

/**
 * Determines whether or not we should hide the patient relationship question on the ApptInfo
 * step in the flow based on the provider.entity_type and a config flag (hide_patient_rel_for_locations)
 * @param {object} provider a provider or bookable entity (e.g. location/clinic)
 * @param {boolean} hidePatientRelForLocations config flag; true means hide the patient rel question
 * @param returns true if we should hide the patient rel question, else false
 */
function hidePatientRel(provider, hidePatientRelForLocations) {
  if (
    provider[ENTITY_TYPE] &&
    provider[ENTITY_TYPE] === LOCATION_ENTITY_TYPE &&
    hidePatientRelForLocations === true
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * Generates a list of modification objects (consumable by getUpdatedSearch)
 * to be used to delete all current query params related to availability tiles.
 * @param {object} query an object representing the search query to be modified
 * @returns {array} list of modification objects to pass to getUpdatedSearch
 */
function getQueryModsToDeleteAvailParams(query) {
  const isAvailabilityFilter = (val) =>
    val &&
    (val.includes(V8_PATIENT_REL_FILTER_KEY) ||
      val.includes(V8_PURPOSE_FILTER_KEY) ||
      val.includes(V8_VISIBILITIES_FILTER_BASE) ||
      val.includes(V8_DIRECT_BOOK_CAPABLE_FILTER));

  let modValues = [];

  if (Array.isArray(query.filter)) {
    modValues = query.filter.filter((f) => {
      return isAvailabilityFilter(f);
    });
  } else {
    if (isAvailabilityFilter(query.filter)) {
      modValues.push(query.filter);
    }
  }

  const availMods = modValues.map((filter) => {
    return { action: 'delete_key_value', key: 'filter', value: filter };
  });

  return availMods;
}

/**
 * Function to determine if the disclaimer message should be shown in the SearchFooter. The disclaimer's '*' correspondes to the '*' shown in 'Available within X days*' message in a provider-tile
 * @param {array} providers
 * @returns {boolean} Returns true if at least one provider has a truthy availabilty_density_best
 */
function showSearchFooterDisclaimer(providers = []) {
  return providers.some((provider) => !!provider.availability_density_best);
}

export {
  searchCategoryDisplayDescriptor,
  getPurposeOptionsByPatientRel,
  splitObjectKeys,
  hidePatientRel,
  getQueryModsToDeleteAvailParams,
  showSearchFooterDisclaimer
};
