// set tracking cookies
import {
  CONSUMER_TRACKING_TOKEN,
  SEARCH_TOKEN,
  USER_TOKEN,
  SEARCH_SHUFFLE_TOKEN,
  SEARCH_ID_TOKEN,
  getToken as getUuidToken
} from './uuid-tracking-tokens';

import type { Request } from '../types/express';

import { getBaseCookieParams } from './tracking-tokens';

import { getRwgTokenValues, saveRwgTokenValues } from './google-rwg-tokens';

import { functionCachedPerRequest } from './cache-per-request';
import { getUserAgentInfo } from './get-user-agent-info';

// always use the same shuffle token for bots, so that they get consistent results when paginating our results pages.
// send hardcoded value instead of cookie in case bots are not storing cookies between page loads
const SEARCH_SHUFFLE_TOKEN_FOR_BOTS = '060e867d-c1aa-404c-8b46-2a9d33dbf6d5';

/**
 * Assign functions to req to fetch various session tokens
 * @param {} req
 * @param {*} next
 * @returns
 */
export async function assignSessionTokenFunctions(req: Request): Promise<void> {
  const appSettings = await req.getAppSettings();
  // guarantee we can only run this once
  if (req.sessionTokenFunctionsAssigned) {
    if (appSettings.DEBUG) {
      req.getLog().debug('Session token functions already assigned');
    }
    return;
  }

  const cookieParams = await getBaseCookieParams(req);

  req.getConsumerTrackingToken = functionCachedPerRequest(
    { req },
    (req: Request) => getUuidToken(req, CONSUMER_TRACKING_TOKEN)
  );
  req.getSearchToken = functionCachedPerRequest({ req }, (req: Request) =>
    getUuidToken(req, SEARCH_TOKEN)
  );
  req.getUserToken = functionCachedPerRequest({ req }, (req: Request) =>
    getUuidToken(req, USER_TOKEN)
  );

  /*
   * Get the search ID for the request, if there is any.
   * Don't cache this because we could assign a value in between the beginning and end of this request
   */
  req.getSearchIdToken = async (): Promise<string | null> => {
    if (req._new_search_id_token) {
      // if we had set a new search id token during this request, use that value
      return req._new_search_id_token;
    }
    // otherwise, get the existing value from cookie or query, if it exists
    return await getUuidToken(req, SEARCH_ID_TOKEN);
  };

  req.setSearchIdToken = function (searchIdToken: string): string {
    if (!SEARCH_ID_TOKEN.validateFunc(searchIdToken)) {
      throw new Error('Invalid search id token');
    }

    req._new_search_id_token = searchIdToken;
    // because this token can change during the request, we need to save it immediately.
    // other tokens are only saved at the beginning of the request which precludes the
    // possibility of a token changing after the beginning but before the end of the req
    req.res.cookie(SEARCH_ID_TOKEN.cookieName, searchIdToken, {
      ...cookieParams,
      // same expiration as short lived user session token
      maxAge:
        (appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] as number) * 1000
    });
    return searchIdToken;
  };

  req.getSearchShuffleToken = functionCachedPerRequest(
    { req },
    async (req: Request) => {
      const { isBot } = await getUserAgentInfo(req);
      if (isBot) {
        return SEARCH_SHUFFLE_TOKEN_FOR_BOTS;
      }
      return await getUuidToken(req, SEARCH_SHUFFLE_TOKEN);
    }
  );

  // rwg (reserve with google) tracking token
  req.getGoogleConversionRwgTokenValues = functionCachedPerRequest(
    { req },
    (req: Request) => getRwgTokenValues(req)
  );

  req.sessionTokenFunctionsAssigned = true;
}

/**
 * Save various session tokens to their various cookies
 * @param {} req
 * @param {*} next
 * @returns
 */
export async function saveSessionTokens(req: Request): Promise<void> {
  const { res } = req;
  const appSettings = await req.getAppSettings();

  const cookieParams = await getBaseCookieParams(req);

  const consumerTrackingToken = await req.getConsumerTrackingToken();
  if (consumerTrackingToken) {
    res.cookie(CONSUMER_TRACKING_TOKEN.cookieName, consumerTrackingToken, {
      ...cookieParams,
      maxAge:
        (appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] as number) * 1000
    });
  }

  const searchShuffleToken = await req.getSearchShuffleToken();
  if (searchShuffleToken) {
    res.cookie(SEARCH_SHUFFLE_TOKEN.cookieName, searchShuffleToken, {
      ...cookieParams,
      // same expiration as short lived user session token
      maxAge:
        (appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] as number) * 1000
    });
  }

  const userToken = await req.getUserToken();
  if (userToken) {
    res.cookie(USER_TOKEN.cookieName, userToken, {
      ...cookieParams,
      maxAge: (appSettings['SECONDS_UNTIL_USER_TOKEN_EXPIRES'] as number) * 1000
    });
  }

  const searchIdToken = await req.getSearchIdToken();
  if (searchIdToken) {
    res.cookie(SEARCH_ID_TOKEN.cookieName, searchIdToken, {
      ...cookieParams,
      // same expiration as short lived user session token
      maxAge:
        (appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] as number) * 1000
    });
  }

  await saveRwgTokenValues(req, res);
}
