/** constants used for logging/Analytics **/

// becomes user_type on client events
// for agent and consumer user_type = persona
export const USER_TYPE_CONSUMER = 'Anonymous';

// becomes project_name on Search Service events
// for agent and consumer product_name = persona
export const PRODUCT_NAME_CONSUMER = 'pmc';

export const SEARCH_PURPOSES = {};

export const QUERY_PARAMS = {
  SEARCH_ID_TOKEN: 'srch',
  SEARCH_SHUFFLE_TOKEN: 'sst',
  USER_TOKEN: 'ut',
  SEARCH_TOKEN: 'st',
  CONSUMER_TRACKING_TOKEN: 'tt'
};

export const COOKIE_NAMES = {
  // not to be confused with SEARCH_TOKEN which does not have a cookie
  SEARCH_ID_TOKEN: 'search_id_token',
  SEARCH_SHUFFLE_TOKEN: 'search_shuffle_token',
  USER_TOKEN: 'consumer_user_token',
  CONSUMER_TRACKING_TOKEN: 'consumer_tracking_token'
};
