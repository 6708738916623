import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { injectIntl } from '@kyruus/intl';
import { useCrmConnector as useCRM } from '@kyruus/crm-connector-widget';
import Cancel from '@kyruus/react-ikons/Cancel';
import Snackbar, { IconButton as SnackbarIconButton } from '@kyruus/snackbar';
import {
  SEARCH_SHUFFLE_TOKEN,
  SEARCH_SHUFFLE_TOKEN_TIMEOUT
} from '../../utils/constants';
import { MODULES, isModuleEnabled } from 'Common/config';
import { setSearchShuffleToken } from '../../behaviors/tokens';
import { messages } from './messages';
import { InvalidPatientDataBanner } from '../InvalidPatientData';

export const CrmConnectorContext = React.createContext();

export const CrmConnectorProvider = connect()(
  injectIntl(function CrmConnectorProvider({
    intl,
    dispatch,
    log,
    config,
    machineOptions = {},
    ...props
  }) {
    const enabled = isModuleEnabled(config, MODULES.CRM_INTEGRATION);
    const isBookingEnabled = isModuleEnabled(config, MODULES.DIRECT_BOOK);
    const crmConnector = useCRM({
      initialContext: { ...config?.crm, ...config?.referral },
      machineOptions: {
        ...machineOptions,
        actions: {
          ...(machineOptions.actions ? machineOptions.actions : {}),
          onInitialization: (ctx, event) => {
            if (enabled) {
              machineOptions?.actions?.onInitialization?.(ctx, event);
              log?.('crm.integration.initialization.success', ctx.envData);
            }
          },
          onInitializationError: (ctx, event) => {
            if (enabled) {
              machineOptions?.actions?.onInitializationError?.(ctx, event);
              log?.('crm.integration.initialization.error', ctx.envData);
            }
          },
          onPatientReceived: (_, event) => {
            if (event.data?.caseId != null) {
              if (machineOptions.actions.onPatientReceived) {
                machineOptions.actions.onPatientReceived(event.data.caseId);
              }

              dispatch(setSearchShuffleToken(event.data.caseId));
              Cookies.set(SEARCH_SHUFFLE_TOKEN, event.data.caseId, {
                expires: SEARCH_SHUFFLE_TOKEN_TIMEOUT,
                path: '/',
                sameSite: 'None',
                secure: true
              });
            }
          }
        }
      }
    });

    const value = {
      enabled,
      connector: crmConnector
    };

    return (
      <>
        {enabled && crmConnector.hasErrors() ? (
          <Snackbar
            key={crmConnector.state.context.errors[0].type}
            mode="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={
              crmConnector.hasErrors() &&
              crmConnector.state.context.errors[0].displayToUser
            }
            message={intl.formatMessage(
              messages[crmConnector.state.context.errors[0].type]
            )}
            onClose={(_, reason) => {
              if (reason !== 'clickaway') {
                crmConnector.dismissError();
              }
            }}
            action={
              <SnackbarIconButton
                color="inherit"
                onClick={crmConnector.dismissError}
              >
                <Cancel />
              </SnackbarIconButton>
            }
          />
        ) : null}
        {enabled && crmConnector.hasPatientValidationErrors() && (
          <InvalidPatientDataBanner
            crmState={crmConnector.state}
            isBookingEnabled={isBookingEnabled}
          />
        )}
        <CrmConnectorContext.Provider value={value} {...props} />
      </>
    );
  })
);
