import axios from 'axios';

import { isMarketableLocation } from '../../utils/location';

/**
 * Returns a GET request url to fetch a set of locations' `primary_marketable_location_id` mloc objects
 * @param {object} locations the location objects
 * @param {string} customerCode the customer code
 * @param {object} req the req object from the server
 * @returns {Promise<string>} the request url
 */
export const getMlocsRequestUrl = async ({ locations, customerCode, req }) => {
  const requestRoot = await (async () => {
    // if on the server make a request to the absolute url
    if (req) {
      const appSettings = await req.getAppSettings();

      return appSettings.LOCATION_V1_URL;
    }

    // if on the client rely on the proxy route (see providermatch_consumer/routes/api.js#21)
    return '/api/v2/locations';
  })();
  const requestUrl = `${requestRoot}/${encodeURIComponent(
    customerCode
  )}/location/get_many`;
  const mlocIds = locations.map((l) =>
    encodeURIComponent(l.primary_marketable_location_id)
  );

  return `${requestUrl}?location_entity_ids=${mlocIds.join(',')}`;
};

/**
 * Returns an options object to be sent along with an mlocs request
 * @param {string} customerCode the customer code
 * @param {object} req the req object from the server
 * @returns {Promise<object>} the request options
 */
export const getMlocsRequestOptions = async ({ customerCode, req }) => {
  const baseOptions = {
    params: { fields: 'MINIMAL' },
    headers: { 'x-consumer-groups': customerCode },
    withCredentials: true
  };

  if (req) {
    const publicJwt = await req.getPublicJwt();
    const optionsWithBearer = {
      ...baseOptions,
      headers: {
        ...baseOptions.headers,
        authorization: `Bearer ${publicJwt}`
      }
    };

    return optionsWithBearer;
  }

  return baseOptions;
};

/**
 * Returns the mloc objects associated with a set location objects based on `location.primary_marketable_location_id`
 * @param {array} locations array of location objects
 * @param {string} customerCode the customer code
 * @param {object} req the req object from the server
 * @returns {Promise<array>} the mloc objects
 */
export const getMlocsForLocations = async ({
  locations,
  customerCode,
  req
}) => {
  try {
    const locationsWithMlocs = locations.filter(isMarketableLocation);

    if (locationsWithMlocs.length === 0) {
      return [];
    }

    const [requestUrl, requestOptions] = await Promise.all([
      getMlocsRequestUrl({ locations: locationsWithMlocs, customerCode, req }),
      getMlocsRequestOptions({ customerCode, req })
    ]);
    const mlocsResp = await axios.get(requestUrl, requestOptions);
    const mlocs = mlocsResp.data._result;

    return mlocs;
  } catch (e) {
    if (req) {
      const log = req.getLog();
      log.error(`error fetching mlocs for ${locations}: ${e}`);
    }
    return [];
  }
};
