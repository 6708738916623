import { STATE_READY } from './states';

export const getProviderById = (state, providerId) => {
  const { providers } = state;
  const providerIdInt = parseInt(providerId, 10);
  if (providers.state === STATE_READY) {
    return (
      (providers.providers || []).find(
        (provider) => provider.id === providerIdInt
      ) || null
    );
  }
  return null;
};

export const getLocationById = (state, locationId) => {
  const { locations } = state;
  if (locations.state === STATE_READY) {
    return (
      (locations._locations || []).find((loc) => loc.id === locationId) || null
    );
  }
  return null;
};

export const getProvidersFromV9Results = (data) => {
  return data._result.map(({ provider, locations }) => {
    return {
      ...provider,
      locations
    };
  });
};

export const getTotalProvidersFromV9Results = (data) => {
  return data._metadata.total_providers;
};
