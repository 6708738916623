import Cookies from 'js-cookie';

export default class State {
  loadStateFromPage() {
    if (this.state) {
      return this.state;
    } else if (document.getElementById('state')) {
      this.state = JSON.parse(
        document.getElementById('state').attributes['content'].value
      );
    } else {
      this.state = {};
    }

    // Hack the customerCode into the redux store if it wasn't set on the server.
    // This should only be required when doing SSR via the flask/render lambda method
    // as rendering in that way doesn't hydrate the entire redux store. This is no longer
    // required when doing SSR with nodejs/express.
    if (!this.state.customerCode) {
      if (document.body.attributes['data-customer-id']) {
        this.state.customerCode =
          document.body.attributes['data-customer-id'].value;
      }
    }

    // Hack the user token fields into the redux store if they weren't set on the server
    // This should only be required when doing SSR via the flask/render lambda method
    // as rendering in that way doesn't hydrate the entire redux store. This is no longer
    // required when doing SSR with nodejs/express.
    if (!this.state.tokens) {
      const tokens = {};
      if (Cookies.get('consumer_tracking_token')) {
        tokens.consumerTrackingToken = Cookies.get('consumer_tracking_token');
      }
      if (Cookies.get('consumer_user_token')) {
        tokens.userToken = Cookies.get('consumer_user_token');
      }
      if (document.getElementById('pmc-search-token')) {
        tokens.searchToken =
          document.getElementById('pmc-search-token').content;
      }
      if (document.getElementById('pmc-anonymous-user-id')) {
        tokens.anonymousUserId = document.getElementById(
          'pmc-anonymous-user-id'
        ).content;
      }
      this.state.tokens = tokens;
    }

    return this.state;
  }
}
