import { useEffect, useState } from 'react';
import {
  USER_LOCATION_KEY,
  USER_LOCATION_EXPIRY,
  GEOLOCATION_CACHE_MAXIMUM_AGE,
  GEOLOCATION_TIMEOUT
} from '../utils/constants';
import useBrowserGeolocation from './useBrowserGeolocation';
import useLocalStorage from './useLocalStorage';
import getHrfAddress from './util/getHrfAddress';
import { logSentryError } from '../utils/logSentryError';

const geolocationOptions = {
  maximumAge: GEOLOCATION_CACHE_MAXIMUM_AGE,
  timeout: GEOLOCATION_TIMEOUT,
  enableHighAccuracy: false
};

/** @description Attempts to retrieve user geolocation data from localStorage,
 * before asking permission from the user to access fresh (or cached) GPS data.
 * Once the coordinates are received,
 * reverse geocoding is done to retrieve an address in human readable format (HRF).
 */
function useUserLocation(shouldExecute) {
  const [storedLocation, setStoredLocation] =
    useLocalStorage(USER_LOCATION_KEY);

  const [userLatLng, setUserLatLng] = useState(null);
  const [userHrfAddress, setUserHrfAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let storedGeolocation, storedHrfAddress, expiry;
  if (storedLocation) {
    [storedGeolocation, storedHrfAddress, expiry] = storedLocation;
  }

  const storedLocationIsValid = storedLocation && Date.now() < expiry;
  const {
    geolocation,
    error: geolocationError,
    isLoading: isBrowserGeolocationLoading
  } = useBrowserGeolocation(geolocationOptions, shouldExecute);

  useEffect(() => {
    let didCancel = false;

    if (!shouldExecute) {
      return;
    }

    if (storedLocationIsValid && storedGeolocation && storedHrfAddress) {
      setUserLatLng(storedGeolocation);
      setUserHrfAddress(storedHrfAddress);
    } else {
      try {
        if (geolocationError) throw geolocationError;

        if (geolocation) {
          const {
            coords: { latitude: lat, longitude: lng },
            timestamp
          } = geolocation;

          setIsLoading(true);

          (async () => {
            const hrfAddress = await getHrfAddress({ lat, lng });

            const latlngStr = `${lat},${lng}`;
            if (!didCancel) {
              setStoredLocation([
                latlngStr,
                hrfAddress,
                timestamp + USER_LOCATION_EXPIRY
              ]);

              setUserLatLng(latlngStr);
              setUserHrfAddress(hrfAddress);
              setIsLoading(false);
            }
          })();
        }
      } catch (e) {
        logSentryError(e);
      }
    }

    return () => {
      didCancel = true;
    };
  }, [storedLocationIsValid, shouldExecute, geolocation, geolocationError]);

  return {
    userLatLng,
    userHrfAddress,
    userLocationIsLoading: Boolean(isLoading || isBrowserGeolocationLoading)
  };
}

export default useUserLocation;
