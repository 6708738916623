import { LOADING_SLOT_COUNTS_START, LOADING_SLOT_COUNTS_END } from '../search';

export const DEFAULT_STATE = false;

export default function slotsCountLoadingReducer(
  state = DEFAULT_STATE,
  action
) {
  switch (action.type) {
    case LOADING_SLOT_COUNTS_START:
      return true;
    case LOADING_SLOT_COUNTS_END:
      return false;
    default:
      return state;
  }
}
