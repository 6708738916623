import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { defineMessages, FormattedMessage } from '@kyruus/intl';

import { isModuleEnabled, MODULES } from 'Common/config';
import LocationInput from './location-input';
import useSuggestions from '../../../../hooks/useSuggestions';
import { LocationFacetBody } from './styles';
import { ClearFilter } from '../clear-filter';
import { IntlOption } from '../../../../utils/intl-components';
import { FacetWrapper } from '../../../styles';

export const DISTANCE_ANY = 'Any';

const messages = defineMessages({
  within: {
    id: 'facet.location.within',
    description: "Title text for a relative distance, e.g. 'Within 5 miles of'",
    defaultMessage: 'Within'
  },
  within_aria_label: {
    id: 'facet.location.within.aria_label',
    description: 'Aria label for the within this distance select input',
    defaultMessage: 'Select within distance'
  },
  anymiles: {
    id: 'facet.location.anymiles',
    description: "Text to display when distance in miles is set to 'Any'",
    defaultMessage: 'Any miles'
  },
  numericalmiles: {
    id: 'facet.location.numericalmiles',
    description:
      'Text to display when distance in miles is set to a numerical value',
    defaultMessage: `{distance, plural,
      one {# mile}
      other {# miles}
    }`
  },
  apply: {
    id: 'facet.location.apply',
    description: 'Button text to apply changes to the distance facet',
    defaultMessage: 'Apply'
  },
  placeholder: {
    id: 'facet.location.placeholder',
    description: 'Placeholder text for the location facet',
    defaultMessage: 'City, State or ZIP'
  }
});

export const LocationFacet = withRouter(
  ({
    config,
    customerConfig,
    searchSummary,
    searchableLocation,
    history,
    getUpdatedSearch,
    log,
    facetName,
    labelName
  }) => {
    const defaultDistance = searchSummary.location
      ? DISTANCE_ANY
      : config.default_distance;

    const [searchParams, setSearchParams] = useState({
      searchLocation: searchSummary.location || '',
      searchDisplayLocation:
        searchSummary.display_location || searchSummary.location || '',
      distance: searchSummary.distance ?? defaultDistance
    });

    // term used to fetch suggestions for
    const [inputSuggestionsTerm, setInputSuggestionsTerm] = useState(undefined);

    // auto-suggest suggestions
    const { suggestions } = useSuggestions({ term: inputSuggestionsTerm });

    useEffect(() => {
      setSearchParams({
        searchLocation: searchSummary.location || '',
        searchDisplayLocation:
          searchSummary.display_location || searchSummary.location || '',
        distance: searchSummary.distance ?? defaultDistance
      });
    }, [
      searchSummary.location,
      searchSummary.display_location,
      searchSummary.distance,
      /** _original_input_distance is needed here to trigger the useEffect
       * when the searchSummary.distance remains constant due to Search V9's auto distance expansion feature */
      searchSummary._original_input_distance,
      defaultDistance
    ]);

    /**
     * Modifies the route history with the current filters and applies them to update the search results
     */
    async function applyFilter({
      searchLocation,
      searchDisplayLocation,
      distance
    }) {
      if (!searchLocation) {
        return;
      }

      const modifications = [
        {
          action: 'append',
          key: 'location',
          value: searchLocation
        },
        {
          action: 'append',
          key: 'display_location',
          value: searchDisplayLocation
        }
      ];

      /** Previously using Search V8, a distance of 0 would return results as if no distance filter was applied (appropriate for any "Any miles" search).
       * However, now in Search V9, a distance of 0 returns results only if they exist at specific location, otherwise the API will auto expand the distance
       * (response's messages.distance_expansion) in specific mile increments until it finds results to return.  */
      if (distance === DISTANCE_ANY) {
        modifications.push({ action: 'delete_key', key: 'distance' });
      } else {
        modifications.push({
          action: 'append',
          key: 'distance',
          value: distance
        });
      }

      const updatedSearch = getUpdatedSearch(modifications);
      history.push(updatedSearch);
    }

    function handleUseCurrentLocation(location, displayLocation, distance) {
      const params = {
        searchLocation: location,
        searchDisplayLocation: displayLocation,
        distance: distance == null ? config.default_distance : distance
      };
      applyFilter(params);
    }

    const { searchLocation, searchDisplayLocation, distance } = searchParams;
    return (
      <FacetWrapper>
        <LocationFacetBody>
          <FormattedMessage {...messages.within} />
          <FormattedMessage {...messages.within_aria_label}>
            {(withinAriaLabelMessage) => (
              <select
                id="distance"
                className="ml-xs mr-xs"
                style={{ maxWidth: '100px' }}
                onChange={(event) => {
                  setSearchParams({
                    searchLocation,
                    searchDisplayLocation,
                    distance: event.target.value
                  });
                  log(
                    'user_action.search_results.distance_facet.within_distance_selected'
                  );
                }}
                value={distance}
                aria-label={withinAriaLabelMessage}
              >
                {(config.distance_options || []).map((distance) => {
                  let descriptor, values;
                  if (distance === DISTANCE_ANY) {
                    descriptor = messages.anymiles;
                  } else {
                    descriptor = messages.numericalmiles;
                    values = { distance };
                  }
                  return (
                    <IntlOption
                      value={distance}
                      key={distance}
                      messageDescriptor={descriptor}
                      messageDescriptorValues={values}
                    />
                  );
                })}
              </select>
            )}
          </FormattedMessage>
          <LocationInput
            searchableLocation={searchableLocation}
            displayLocation={searchDisplayLocation}
            suggestions={suggestions}
            onChange={(selectedItem) => {
              const params = {
                distance,
                searchLocation: selectedItem,
                searchDisplayLocation: selectedItem
              };
              setSearchParams(params);
              applyFilter(params);
              log('user_action.search_results.distance_facet.search_location');
            }}
            onInputValueChange={(inputValue) => {
              setSearchParams({
                distance,
                searchLocation: inputValue,
                searchDisplayLocation: inputValue
              });
              setInputSuggestionsTerm(inputValue);
            }}
            onSubmit={() => {
              applyFilter(searchParams);
              log('user_action.search_results.distance_facet.search_location');
            }}
            placeholder={messages.placeholder}
            /**
             * If CRM is enabled then the useCurrentLocation button causes a spinner of death because the application is iframed without geolocation access
             * If CRM is enabled or enable_use_current_location flag is not checked then return false otherwise true
             */
            enableUseCurrentLocation={
              !isModuleEnabled(customerConfig, MODULES.CRM_INTEGRATION) &&
              Boolean(config?.enable_use_current_location)
            }
            onUseCurrentLocation={handleUseCurrentLocation}
            distance={distance}
            log={log}
          />
        </LocationFacetBody>
        <ClearFilter
          config={config}
          facetName={facetName}
          searchableLocation={searchableLocation}
          log={log}
          getUpdatedSearch={getUpdatedSearch}
          labelName={labelName}
        />
      </FacetWrapper>
    );
  }
);

LocationFacet.displayName = 'LocationFacet';
