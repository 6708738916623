export const ACCEPTED_DEEP_LINKING_PARAMS_MAP = {
  purpose: 'purpose',
  relationship: 'patientRel',
  location_id: 'locationId',
  period_start: 'periodStart'
};

export const FHIR_PID_TYPE = 'patient_fhir_id';

export const EHR_AUTH_SCOPE = 'patient/Patient.read'; // http://www.hl7.org/fhir/smart-app-launch/scopes-and-launch-context/index.html#patient-specific-scopes
export const EHR_AUTH_RESPONSE_TYPE = 'code';

// Supported EHRs
export const EHR_CERNER = 'cerner';
export const EHR_MYCHART = 'mychart';
export const EHR_MYCHART_EMBEDDED = 'mychart_embedded';
export const EHR_NONE = 'none';
export const EHR_CRM = 'crm';

// Cookie attributes
export const COOKIE_EXPIRES_IN_HALF_HOUR = 1 / 48; // See https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-make-the-cookie-expire-in-less-than-a-day
export const COOKIE_EXPIRES_IN_TWO_MINUTES = new Date(
  new Date().getTime() + 2 * 60 * 1000
);
export const COOKIE_DEFAULT_OPTIONS = {
  // Using sameSite 'lax' due to bug on Safari 14:
  // sameSite 'strict' even w/ a specified domain as per https://stackoverflow.com/a/70545461 causes saved cookies to be invisible (inaccessible by JS) until the page is refreshed one or more times
  sameSite: 'lax',
  ...(process.env.NODE_ENV === 'production' ? { secure: true } : {})
};

// these values are also used in the Elasticsearch Indexer when setting a provider's book online url
export const PMC_DBW_ENABLED_PROPERTY = 'direct_book_pmc';
export const PMAC_DBW_ENABLED_PROPERTY = 'direct_book_pmac';

export const DBW_DRAWER_MAX_WIDTH = 550;
