import { useCallback, useEffect, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';
import _memoize from 'lodash/memoize';

const UPDATE_SUGGESTIONS_DEBOUNCE = 200;

export async function _getPlacesData(input) {
  let data;

  try {
    const response = await fetch(`/aws-maps/autocomplete/${input}`);
    if (!response.ok) {
      throw new Error(response.message);
    }
    data = await response.json();
  } catch (error) {
    // Return empty data if the places endpoint is unavailable/returns bad data
    data = {};
  }

  return data;
}

export default function useSuggestions({ term }) {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  const getPlacesDataMemoized = useCallback(_memoize(_getPlacesData), []);

  const getSuggestionsData = useCallback(
    async (inputValue) => {
      let suggestions = [];

      if ((inputValue || '').trim() !== '') {
        setSuggestionsLoading(true);
        const placesData = await getPlacesDataMemoized(inputValue);
        suggestions = ((placesData && placesData.Results) || [])
          .filter((result) => result.PlaceId)
          .map((result) => result.Text);
      }

      setSuggestions(suggestions);
      setSuggestionsLoading(false);
    },
    [getPlacesDataMemoized]
  );

  const handleRef = useRef(null);

  useEffect(() => {
    updateSuggestions(term);
  }, [term, updateSuggestions]);

  const updateSuggestions = useCallback(
    (inputValue) => {
      // don't make an autocomplete call when a user selects an option from the dropdown
      if (suggestions.includes(inputValue)) return;

      // cancel any currently pending ('debounced') calls in favor of the last call deferred
      if (handleRef.current && typeof handleRef.current.cancel === 'function') {
        handleRef.current.cancel();
      }

      // save ref to last call deferred
      handleRef.current = _debounce(
        getSuggestionsData,
        UPDATE_SUGGESTIONS_DEBOUNCE
      );
      return handleRef.current(inputValue);
    },
    [suggestions, getSuggestionsData]
  );

  return { suggestions, suggestionsLoading };
}
