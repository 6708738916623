/**
 * Manually report an error to Sentry if we are in a Sentry-initialized browser context
 * @param error {Error|String} - The exception or error message we want to manually report to Sentry. If the type is String, then Raven's implementation actually calls `captureMessage` rather than `captureException`.
 * @param metaData {Object} - Optional associated meta data sent to Sentry that may be helpful for debugging (no PHI)
 */

export function logSentryError(error, metaData) {
  if (
    typeof window !== 'undefined' &&
    window.Raven &&
    window.Raven.captureException
  ) {
    metaData
      ? window.Raven.captureException(error, {
          extra: metaData
        })
      : window.Raven.captureException(error);
  }
}
