import _set from 'lodash/set';
import _omit from 'lodash/omit';
import _difference from 'lodash/difference';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import querystring from 'querystring';
import axios from 'axios';

import {
  saveSearchIdResultsData,
  handleSearchIdTracking
} from 'Common/logging/search-id';

import { splitObjectKeys } from '../../search/utils';
import { fetchSlots } from '../availability/actions';
import { visibilitySelector } from '../configuration/selectors';

import {
  getStickyParamsFromLocation,
  getBaseUrlFromRequest
} from '../../utils/url';
import getHttpsAgent from '../../utils/getHttpsAgent';
import getRequestHeaders from '../../utils/getRequestHeaders';

import { CERNER_BCS_TOKEN_QUERY_PARAM } from '../../utils/cerner';
import { isSearchAlertsEnabled } from 'Common/config';
import { productNameSelector } from '../product-name';
import { splitOnce } from 'Common/utils/splitOnce';

export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const RECEIVE_SEARCH_ERROR = 'RECEIVE_SEARCH_ERROR';

export const UPDATE_PROVIDER_SLOT_COUNTS = 'UPDATE_PROVIDER_SLOT_COUNTS';
export const LOADING_SLOT_COUNTS_START = 'LOADING_SLOT_COUNTS_START';
export const LOADING_SLOT_COUNTS_END = 'LOADING_SLOT_COUNTS_END';
export const ERROR_SLOT_COUNTS = 'ERROR_SLOT_COUNTS';

export const TOGGLE_MOBILE_FACETS = 'TOGGLE_MOBILE_FACETS';

export const UPDATE_PARAMS = 'UPDATE_PARAMS';

//TODO: KENG-34840 Convert mod.action into constants for use across PMC

function requestProviders() {
  return {
    type: REQUEST_SEARCH
  };
}

export function receiveProviders(responseData, isSpecialtyLocationSSR) {
  return {
    type: RECEIVE_SEARCH,
    payload: {
      providers: responseData['providers'],
      totalProviders: responseData['total_providers'],
      totalPages: responseData['total_pages'],
      facets: responseData['facets'],
      suggestions: responseData['suggestions'],
      query: responseData['query'],
      topSpecialties: responseData['top_specialties'],
      searchSummary: {
        ...responseData['search_summary'],
        query_string: isSpecialtyLocationSSR
          ? ''
          : responseData['search_summary'].query_string
      },
      sortOptions: responseData['sort_options'],
      searchContext: responseData['search_context'],
      alerts: responseData['alerts']
    }
  };
}

function updateProvidersAfterAppendingSlotCounts(providers) {
  return {
    type: UPDATE_PROVIDER_SLOT_COUNTS,
    payload: {
      providers
    }
  };
}

function receiveProvidersError() {
  return {
    type: RECEIVE_SEARCH_ERROR
  };
}

function loadingSlotCountsStart() {
  return {
    type: LOADING_SLOT_COUNTS_START
  };
}

function loadingSlotCountsEnd() {
  return {
    type: LOADING_SLOT_COUNTS_END
  };
}

function errorSlotCounts() {
  return {
    type: ERROR_SLOT_COUNTS
  };
}

export function fetchProviderSlotCounts(nextLocation, { req } = {}) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const { customerCode, config } = state;
      const productName = productNameSelector(state);
      const { schedulingVisibility } = visibilitySelector(state);
      if (config.display_availability_in_search) {
        dispatch(loadingSlotCountsStart());

        const { relationshipParam, purposeParam } =
          getRelationshipAndPurposeParams(nextLocation);

        const {
          search: { providerIds }
        } = state;

        let { providers } = state;

        // providers will be mutated below
        // hence, making a copy first
        providers = { ...providers };

        let url;
        const urlPath = createSlotCountQueryUrl(
          providerIds,
          relationshipParam,
          purposeParam,
          customerCode,
          schedulingVisibility
        );

        if (req) {
          const appSettings = await req.getAppSettings();
          url = `${appSettings.SEARCH_V9_URL}/${urlPath}`;
        } else {
          url = `/api/searchservice-v9/${urlPath}`;
        }

        const slotResponse = await axios.get(url, {
          headers: {
            'x-consumer-groups': customerCode,
            'x-consumer-username': productName
          }
        });

        if (slotResponse.status !== 200) {
          throw new Error();
        }

        const slotData = slotResponse.data;

        if (slotData.facets[0] && slotData.facets[0].terms) {
          providers = Object.values(providers);

          providerSlotCountZip(providers, slotData.facets[0].terms);

          dispatch(updateProvidersAfterAppendingSlotCounts(providers));
        }

        dispatch(loadingSlotCountsEnd());

        if (providerIds.length && relationshipParam && purposeParam) {
          await dispatch(
            fetchSlots(
              {
                relationship: splitOnce(relationshipParam, ':')[1],
                purpose: splitOnce(purposeParam, ':')[1]
              },
              providerIds
            )
          );
        }
      }
    } catch (e) {
      dispatch(loadingSlotCountsEnd());
      // notify availability reducer of error so as not to render <AvailabilityControls />
      dispatch(errorSlotCounts());
    }
  };
}

export function fetchProviders(nextLocation, { req } = {}) {
  return async (dispatch, getState) => {
    dispatch(requestProviders());
    const state = getState();
    try {
      let queryParams = querystring.parse(nextLocation.search.slice(1));

      // strip out query params we don't want to pass to search service.
      // really this should be a whitelist instead of a blacklist, but we can wait
      // until v9 migration -- and proper ts types -- to set that up
      queryParams = _omit(queryParams, [CERNER_BCS_TOKEN_QUERY_PARAM]);

      queryParams = {
        ...queryParams,
        include_alerts: isSearchAlertsEnabled(state.config) ? true : false
      };

      let isSpecialtyLocationSSR = false;

      if (req) {
        if (req.params.specialty) {
          queryParams.specialty_all = req.params.specialty;
        }

        if (req.params.location) {
          // this logic was migrated from /providermatch_consumer/api/helpers/search_service.py's `search_response` method
          queryParams.location = req.params.location;

          queryParams.sort = (function distanceSort() {
            const locationFacet = state.config.location_facet || {};
            const sortOrder = locationFacet.custom_sort_order || 'distance';
            return sortOrder;
          })();

          queryParams.distance = (function defaultDistance() {
            const locationFacet = state.config.location_facet || {};
            const defaultDistance = locationFacet.miles || '25';
            return defaultDistance;
          })();
        }

        if (
          queryParams.specialty_all ||
          (queryParams.location && queryParams.sort && queryParams.distance)
        ) {
          isSpecialtyLocationSSR = true;
        }
      }

      await handleSearchIdTracking({ searchParams: queryParams, req });

      const baseUrl = await getBaseUrlFromRequest(req);
      const headers = await getRequestHeaders(req);

      const searchResponse = await axios.get(
        `${baseUrl}/api/search?${querystring.stringify(queryParams)}`,
        {
          withCredentials: true,
          // allow local certs for development server side
          httpsAgent: getHttpsAgent(req),
          headers
        }
      );

      if (searchResponse.status !== 200) {
        throw new Error();
      }

      const searchData = searchResponse.data.data;

      await saveSearchIdResultsData({
        searchParams: queryParams,
        searchResults: searchData,
        req
      });

      // @todo: this doesn't belong in the action, as the action is
      // fired both on the client and server
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }

      dispatch(receiveProviders(searchData, isSpecialtyLocationSSR));
    } catch (error) {
      dispatch(receiveProvidersError(error));
    }
  };
}

function updateListModification(query, mod) {
  let valueList = [].concat(query[mod.key] || []);
  if (mod.action == 'append') {
    if (Array.isArray(mod.value)) {
      valueList = Object.assign(
        {},
        ...splitObjectKeys(valueList),
        ...splitObjectKeys(mod.value)
      );
      valueList = Object.keys(valueList).map(
        (item) => `${item}:${valueList[item]}`
      );
    }
    const newValues = [...new Set(valueList.concat(mod.value).filter(Boolean))];
    return _set(query, mod.key, newValues);
  } else if (mod.action == 'delete_key_value') {
    const filteredValues = _difference(valueList, [].concat(mod.value));
    if (_isEmpty(filteredValues)) {
      return _omit(query, mod.key);
    } else {
      return _set(query, mod.key, filteredValues);
    }
  } else if (mod.action == 'delete_key') {
    return _omit(query, mod.key);
  }
}

function getCustomDistanceSort(config) {
  return _get(config, 'location_facet.custom_sort_order', 'distance');
}

function shouldResetSort(config, query) {
  return !query.location && query.sort == getCustomDistanceSort(config);
}

export function getUpdatedSearch(
  config,
  currentQuery,
  currentLocation,
  modifications
) {
  modifications.unshift({ action: 'delete_key', key: 'page' });

  // TODO KP-12123 use kqmodjs for parsing query modifications
  let query = modifications.reduce((result, mod) => {
    if (mod.key == 'filter') {
      return updateListModification(result, mod);
    } else if (mod.action == 'append') {
      return _set(result, mod.key, mod.value);
    } else if (mod.action == 'delete_key') {
      return _omit(result, mod.key);
    }
  }, Object.assign({}, currentQuery));

  // Reset sort when distance or availability is absent
  if (shouldResetSort(config, query)) {
    query['sort'] = config.search_widget.sort_order;
  }

  if (currentLocation) {
    query = {
      ...query,
      ...getStickyParamsFromLocation(currentLocation)
    };
  }

  return {
    pathname: currentLocation.pathname,
    search: querystring.stringify(query)
  };
}

export function toggleMobileFacets() {
  return {
    type: TOGGLE_MOBILE_FACETS
  };
}

function createSlotCountQueryUrl(
  providerIds,
  relationshipParam,
  purposeParam,
  customerCode,
  visibility
) {
  const params = {
    _filter: `provider_id:${providerIds.join('#')}`,
    filter: [`visibilities.${visibility}`],
    facet: 'provider_id#location_id'
  };

  if (relationshipParam && purposeParam) {
    params.filter.push(
      `patient_relationship:${splitOnce(relationshipParam, ':')[1]}`
    );
    params.filter.push(`purpose:${splitOnce(purposeParam, ':')[1]}`);
  }

  return `${customerCode}/slots?${querystring.stringify(params)}`;
}

function providerSlotCountZip(providers, slotData) {
  for (const providerSlots of slotData) {
    const providerId = providerSlots.value;
    if (providerSlots.terms && providerSlots.terms.length > 0) {
      const provider = providers.find((value) => {
        return value.id == providerId;
      });
      for (const locationSlots of providerSlots.terms) {
        const locationId = locationSlots.value;
        const location = provider.locations.find((value) => {
          return value.id == locationId;
        });
        if (location) {
          location.slots = true;
        }
      }
    }
  }
}

function getRelationshipAndPurposeParams(nextLocation) {
  const { search = '' } = nextLocation;

  const queryParams = querystring.parse(search.slice(1));

  // Get appointment purpose if existing
  const filterIsArray = queryParams.filter && Array.isArray(queryParams.filter);

  const relationshipParam =
    filterIsArray &&
    queryParams.filter.find((value) => {
      return /^appointment_ehr_purposes\.patient_relationship/.test(value);
    });

  const purposeParam =
    filterIsArray &&
    queryParams.filter.find((value) => {
      return /^appointment_ehr_purposes\.name/.test(value);
    });

  return {
    relationshipParam,
    purposeParam
  };
}
