import _kebabCase from 'lodash/kebabCase';
import { withoutTrailingSlash } from './util';

/**
 * Returns the canonical URL for the link tag on the MLOC page
 * @param {string} host the host from the config (added in the proxy request pipeline)
 * @param {object} location the location object that comes back from location service on the MLOC page
 * @returns
 */
export const getLocationUrl = (host, locationName, locationId) => {
  return `${withoutTrailingSlash(host)}${getLocationPath(
    locationName,
    locationId
  )}`;
};

export const getLocationPath = (locationName, locationId) => {
  const locationNamePiece = encodeURIComponent(_kebabCase(locationName));
  const locationIdPiece = encodeURIComponent(`${locationId}`.toLowerCase());
  // the location objects that come back from location service have _entity_id, not primary_marketable_location_id
  // primary_marketable_location_id comes back in the 'locations' object for provider results on SERP (v9)
  // the location results from the locations endpoint for v9 brings back 'id' though, not _entity_id...
  return `/location/${locationNamePiece}/${locationIdPiece}`;
};
