import _cloneDeep from 'lodash/cloneDeep';
import { LOCATION_FACET_DEFAULT_DISTANCE } from '../utils/constants';
import { splitOnce } from 'Common/utils/splitOnce';

export default class FacetConfigurator {
  /**
   * example facetsConfig
   * comes from customerService
   * [
      {
        field: 'provider.insurance_accepted',
        input_type: 'radio',
        ignore: ['Test'],
        order_by_custom: [
          'Broadway Health Insurance',
          'Aetna'
        ]
      }
    ]
   */
  constructor(config = {}, filteredFacets = [], addLocationFacet = false) {
    this.customerConfig = config;
    this.facetsConfig = config.facets_v9;
    this.filteredFacets = filteredFacets;
    this.addLocationFacet = addLocationFacet;
    this.constants = {
      DEFAULT_INPUT_TYPE: 'checkbox',
      DEFAULT_DISTANCE: LOCATION_FACET_DEFAULT_DISTANCE,
      DEFAULT_DISTANCE_OPTIONS: ['Any', '1', '5', '10', '25', '50', '100'],
      DEFAULT_ALLOW_LOCATION_CHANGE: true,
      DEFAULT_ENABLE_USE_CURRENT_LOCATION: true
    };
  }

  /**
   * Private method to update a facet to indicate if it pertains to a currently active query filter.
   *
   * @param {object} facet a facet returned from search service, to update if it's being actively filtered on or not
   * @param {array} filterParams filter parameters from the current query
   * @returns {object} copy of the facet passed in with modifications applied
   */
  #updateFacetByApplied(facet, filterParams) {
    facet = _cloneDeep(facet);
    facet.terms = facet.terms.map((term) => {
      const field = `${facet.field}:${term.value}`;
      const isAppliedFilter = filterParams.some((f) => f === field);

      term.filter_param = field;
      term.applied = isAppliedFilter;

      return term;
    });
    return facet;
  }

  /**
   * Private method to get a single facet's configuration from the customer's `facetsConfig`.
   *
   * @param {string} field
   * @returns {object} - the matching field from the `facetsConfig`
   */
  #getFacetConfigByField(field) {
    return this.facetsConfig.find((item) => item.field === field);
  }

  /**
   * Private method to remove any terms that should be ignored from a facet's `terms` array. Also updates the facet's `total`, subtracting counts of any ignored terms.
   *
   * @param {object} facet a facet returned from search service, to trim of terms to ignore
   * @returns {object} copy of the facet passed in with modifications applied
   */
  #removeTermsToIgnoreAndUpdateTotal(facet) {
    facet = _cloneDeep(facet);
    const facetConfig = this.#getFacetConfigByField(
      facet.field,
      this.facetsConfig
    );
    const ignoredTerms = (facetConfig && facetConfig.ignore) || [];
    facet.terms = facet.terms.filter(
      (term) => !ignoredTerms.includes(term.value)
    );
    // overall total will not include ignored terms in count
    facet.total = facet.terms.reduce((acc, term) => {
      acc += term.count;
      return acc;
    }, 0);
    return facet;
  }

  /**
   * Private method to order a facet's terms according to a customer's configuration.
   * @param {object} facet a facet returned from search service, to trim of terms to ignore
   * @returns {object} copy of the facet passed in with terms ordered according to a customer's configuration
   */
  #orderTerms(facet) {
    // this is a JS implementation of the `facets.py` method `ordered_terms`
    facet = _cloneDeep(facet);

    const currentFacetConfig = this.#getFacetConfigByField(
      facet.field,
      this.facetsConfig
    );

    if (currentFacetConfig?.order_by_custom) {
      const termMap = facet.terms.reduce((acc, term) => {
        acc[term.value] = term;
        return acc;
      }, {});
      const allOrderedTerms = [];
      for (const orderedTerm of currentFacetConfig.order_by_custom) {
        const term = termMap[orderedTerm];
        if (term) {
          allOrderedTerms.push(term);
        }
      }
      facet.terms = allOrderedTerms;
      return facet;
    }

    if (currentFacetConfig?.order_by === 'frequency') {
      // sort by term.count, descending
      facet.terms.sort((a, b) => {
        if (a.count < b.count) return 1;
        if (b.count > a.count) return -1;
        return 0;
      });
      return facet;
    }

    // default - sort by term.value, ascending
    facet.terms.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (b.value > a.value) return 1;
      return 0;
    });
    return facet;
  }

  /**
   * Private method to orchestrate necessary transformations to `facets` per a customer's `facetsConfig` and the current query.
   *
   * @param {array} facets an array of facets returned from search service, to configure per the customer's `facetsConfig`
   * @param {array} filterParams the current query's filter parameters
   * @param {array} extraFilters
   * @returns {array} copy of the facets array passed in, with configuration updates applied
   */
  #getConfiguredFacetsByType(facets, filterParams, extraFilters) {
    const { filteredFacets } = this;
    const updatedFacets = facets
      .filter((facet) => !filteredFacets.includes(facet.field))
      .map((facet) => {
        const facetConfigByField = this.#getFacetConfigByField(
          facet.field,
          this.facetsConfig
        );
        const input_type = facetConfigByField && facetConfigByField.input_type;

        const facetWithIgnoredTermsRemoved =
          this.#removeTermsToIgnoreAndUpdateTotal(facet, this.facetsConfig);

        const facetWithOrderedTerms = this.#orderTerms(
          facetWithIgnoredTermsRemoved
        );

        const facetUpdatedbyApplied = this.#updateFacetByApplied(
          facetWithOrderedTerms,
          filterParams
        );

        facetUpdatedbyApplied.type =
          input_type || this.constants.DEFAULT_INPUT_TYPE;

        return facetUpdatedbyApplied;
      });

    (extraFilters || []).forEach((extraFilter) => {
      const [filterName, filterValue] = splitOnce(extraFilter, ':');
      // if this filter doesn't exist in the facets list already, add it
      if (
        !updatedFacets.find((facet) => facet.field === filterName) &&
        !filteredFacets.includes(filterName)
      ) {
        updatedFacets.push({
          field: filterName,
          type: 'checkbox', // this type means it will not appear in the filter UI
          terms: [
            {
              filter_param: extraFilter,
              value: filterValue,
              applied: true
            }
          ]
        });
      }
    });

    return updatedFacets;
  }

  /**
   * Private method that adds the location facet to the beginning of the facets if it should be added.
   * The location facet is configured based upon either the customer's configuration or application defaults.
   *
   * @returns {array} The passed in array with the location facet at the front, if it should be added
   */
  #withConfiguredLocationFacet(facets) {
    var modifiedFacets = facets;
    const facetConfig = this.customerConfig.location_facet || {};
    const defaultDistance =
      facetConfig.miles || this.constants.DEFAULT_DISTANCE;
    const distanceOptions =
      facetConfig.options || this.constants.DEFAULT_DISTANCE_OPTIONS;
    const allowLocationChange =
      facetConfig.allow_location_change ??
      this.constants.DEFAULT_ALLOW_LOCATION_CHANGE;
    const enableUseCurrentLocation =
      facetConfig.enable_use_current_location ??
      this.constants.DEFAULT_ENABLE_USE_CURRENT_LOCATION;

    const locationFacet = {
      field: 'location',
      type: 'location',
      placeholder_text: facetConfig.placeholder,
      default_distance: defaultDistance,
      distance_options: distanceOptions,
      allow_location_change: allowLocationChange,
      enable_use_current_location: enableUseCurrentLocation
    };

    modifiedFacets = [locationFacet].concat(facets);

    return modifiedFacets;
  }

  /**
   * Public method that takes in `facets` and returns a copy of that array with necessary transformations applied per a customer's `facetsConfig` and the current query.
   *
   * @param {array} facets an array of facets returned from search service, to configure per the customer's facetConfig
   * @param {object} queryParams the current query parameters, in dictionary format
   * @param {array} extraFilters extra filters applied to the search. Any filters in this list not in facets will be added to the final list. Used for filters that come back from v9 that might not be part of the initial config such as in NLP
   * @returns {array} an array of configured facets
   */
  getConfiguredFacets(facets = [], queryParams, extraFilters) {
    // convert filter param into array if needed
    let { filter: filterParams } = queryParams;
    if (!Array.isArray(filterParams)) {
      filterParams = [filterParams];
    }

    const configuredFacets = this.#getConfiguredFacetsByType(
      facets,
      filterParams,
      extraFilters
    );

    return this.addLocationFacet === true
      ? this.#withConfiguredLocationFacet(configuredFacets)
      : configuredFacets;
  }
}
