import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';
import _isFunction from 'lodash/isFunction';
import _forOwn from 'lodash/forOwn';

/**
 * Recursively iterates over the properties of an object and freezes
 * all values, rendering it immutable.
 * @param obj object to be frozen
 * @return immutable object
 */
export const deepFreeze = (obj) => {
  if (_isObject(obj) || _isArray(obj) || _isFunction(obj)) {
    Object.freeze(obj);
    _forOwn(obj, deepFreeze);
  }
  return obj;
};
