import isUaWebview from 'is-ua-webview';
import isbot from 'isbot';

import type { Request } from '../types/express';

type UserAgentInfo = {
  isWebview: boolean;
  isBot: boolean;
};

/**
 * Returns info about the user-agent:
 * - isWebview
 * - isBot
 * Wraps the webview `isUaWebview()` and bot `isbot()` detectors,
 * unless the callback default params are overriden.
 * @param {object} req
 * @returns {Promise<UserAgentInfo>} object containing isWebview and isBot, both holding boolean values
 */
async function getUserAgentInfo(req: Request): Promise<UserAgentInfo> {
  try {
    const appSettings = await req.getAppSettings();

    const ua = req.headers['user-agent'];
    if (ua == null) {
      throw Error(`'user-agent' is undefined in req.headers`);
    }

    let isBot = false;
    if (
      appSettings.DEBUG &&
      (req.headers['user-agent'] || '').includes('HeadlessChrome/')
    ) {
      /** excluding HeadlessChrome as Jenkins runs testcafe with headless Chrome */
      isBot = false;
    } else {
      isBot = isbot(ua);
    }

    const data = {
      isWebview: isUaWebview(ua),
      isBot
    };
    return data;
  } catch (error) {
    const log = req.getLog();
    log.debug(`failed to detect 'user-agent': ${(error as Error).message}`, {
      error,
      eventName: 'pmc.user_agent_detection_failed'
    });
    /** without a user-agent, assume our checks are false */
    return {
      isWebview: false,
      isBot: false
    };
  }
}

export { getUserAgentInfo };
