import { SET_CSRF_TOKEN } from './actions';

export const DEFAULT_STATE = '';

export default function csrfTokenReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CSRF_TOKEN:
      return action.payload;
    default:
      return state;
  }
}
