import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import loading from '../behaviors/loading';
import error from '../behaviors/error';
import search from '../behaviors/search';
import searchV9 from '../behaviors/search-v9';
import providers from '../behaviors/providers';
import profiles from '../behaviors/profiles';
import config from '../behaviors/configuration';
import settings from '../behaviors/settings';
import searchWidgetConfig from '../behaviors/search-widget';
import searchMap from '../behaviors/search-map';
import searchMapV9 from '../behaviors/search-map-v9';
import availability from '../behaviors/availability';
import slotsCountLoading from '../behaviors/slotsCountLoading';
import csrfToken from '../behaviors/csrf-token';
import locations from '../behaviors/locations';
import customerCode from '../behaviors/customer-code';
import tokens from '../behaviors/tokens';
import authenticatedUserInfo from '../behaviors/authenticatedUserInfo';
import device from '../behaviors/device';
import deployment from '../behaviors/deployment';

const rootReducer = combineReducers({
  loading,
  error,
  search,
  searchV9,
  providers,
  profiles,
  config,
  authenticatedUserInfo,
  settings,
  searchWidgetConfig,
  availability,
  slotsCountLoading,
  csrfToken,
  locations,
  searchMap,
  searchMapV9,
  customerCode,
  tokens,
  device,
  deployment
});

export default function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState, applyMiddleware(thunk));
}
