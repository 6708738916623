import { useState, useEffect } from 'react';
import axios from 'axios';
import { getCernerFpa, CERNER_PID_TYPE } from '../utils/cerner';
import { getResolvedSearchLocation } from '../utils/location';

/**
 * Get the assumed zip / postal code to use for the search by default before any user interaction
 * @param {Object} * {
 *   config: customer Config from redux;
 *   bcsToken: cerner bcs token, generally from redux
 * }
 * @returns {Object} * {
 *   zip: ccf user zipcode if one was fetched
 *   isLoading: if ccf demographics are in the loading state
 *   error: an error, if any, raised when getting demographics
 * }
 */
export default function useCcfDemographicsLocation({
  config,
  customerCode,
  bcsToken
}) {
  // zipcode fetched from demographics, if any
  const [fetchedZip, setFetchedZip] = useState(undefined);
  // searchLocation, which will either be the zip code, or lat/lon coords if we can get them
  const [searchLocation, setSearchLocation] = useState(undefined);
  const [error, setError] = useState(undefined);

  const cernerFpa = getCernerFpa(bcsToken);

  const enableCcfDemographicsZip =
    Boolean(config?.third_party?.cerner_healthelife?.search_auto_radius) &&
    Boolean(cernerFpa);

  // if we're going to make the call, make the initial state say so in case we need the value
  // to delay some other dependent call
  const [isPending, setIsPending] = useState(enableCcfDemographicsZip);

  useEffect(() => {
    if (!enableCcfDemographicsZip) {
      return;
    }

    async function getZip() {
      try {
        // base the search call on zip code from demographics if we're in an env with a bcs_token (cerner)
        // and the user hasn't overridden the search location
        // then the app is in CCF context

        setIsPending(true);

        const demographicsResponse = await fetchEhrPatientDemographics({
          customerCode,
          pid: cernerFpa,
          pid_type: CERNER_PID_TYPE
        });

        let zip = demographicsResponse?.result?.address?.zip;

        if (typeof zip === 'string' && zip.length > 5) {
          zip = zip.substring(0, 5);
        }

        let searchLocation = zip;

        try {
          searchLocation = await getResolvedSearchLocation(zip);
        } catch (e) {
          // ignore, we'll just use `zip` as the location
        }

        setSearchLocation(searchLocation);
        setFetchedZip(zip);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsPending(false);
      }
    }
    getZip();
  }, [bcsToken, enableCcfDemographicsZip]);

  return {
    patientZipSearchLocation: searchLocation,
    patientZip: fetchedZip,
    patientZipPending: isPending,
    patientZipError: error
  };
}

async function fetchEhrPatientDemographics({ customerCode, pid, pid_type }) {
  const response = await axios.post(
    `/api/v2/appointments/${encodeURIComponent(customerCode)}/patients/lookup`,
    {
      patient_id: { pid, pid_type }
    }
  );

  return response.data;
}
