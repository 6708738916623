import { RefObject } from 'react';
import { PMCConfig } from '@kyruus/types';
import { Provider } from '@kyruus/provider-components';
import { Search } from '@kyruus/search-sdk';

import { AppRequest, Tokens } from 'Src/types';
import { getCareTeamProvidersSort } from './utils';
import { getConfigProperty } from 'Common/config';

const MAX_CARE_TEAM_PROVIDERS = 16;

interface GetCareTeamProvidersProps {
  provider: Provider;
  customerCode: string;
  config: PMCConfig;
  tokens: Tokens;
  searchSDK: RefObject<Search>;
  req: AppRequest;
}

export const getCareTeamProviders = async ({
  provider,
  customerCode,
  config,
  tokens,
  searchSDK,
  req
}: GetCareTeamProvidersProps) => {
  try {
    const careTeamFeatureFlag = getConfigProperty(
      config,
      'feature_flags.provider_profile_care_team',
      false
    );
    if (careTeamFeatureFlag !== true) {
      return [];
    }
    /**
    care_team is not a standard xp field. it should be an array
    but there is no validation and misconfiguration is possible
    */
    const careTeams = Array.isArray(provider.care_team)
      ? provider.care_team
      : [];

    const searchClient = searchSDK.current;

    if (!careTeams.length || !searchClient) {
      return [];
    }

    const providerSort = getCareTeamProvidersSort(
      config.search_widget?.sort_order
    );

    const params = {
      context: config.index || customerCode,
      tracking_token: tokens.consumerTrackingToken,
      search_token: tokens.searchToken,
      user_id: tokens.anonymousUserId,
      user_token: tokens.userToken,
      per_page: MAX_CARE_TEAM_PROVIDERS,
      page: 1,
      provider_fields: [
        '-ALL',
        'id',
        'name',
        'image_url',
        'specialties',
        'book_online_url',
        'care_team'
      ].join(','),
      location_fields: '-ALL',
      availability_fields: ['-ALL', 'meta'].join(','),
      sort: [providerSort],
      filter: {
        or: {
          'provider.care_team': careTeams
        },
        not: {
          'provider.id': [provider.id]
        }
      },
      exclude_from_analytics: true
    };
    const response = await searchClient.getProviders(params);

    return response.data._result;
  } catch (e) {
    if (req) {
      const log = req.getLog();
      log.error(`error fetching care team providers: ${e}`);
    }
    return [];
  }
};
