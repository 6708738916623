import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const BannerWrapper = styled('div')`
  position: sticky;
  top: 0;
  z-index: 100;
  margin-top: ${fromTheme('spacing_medium')};
`;

export const MessageWrapper = styled('div')`
  font-size: ${fromTheme('font_size')};
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: ${fromTheme('spacing_small')};
`;

export const InvalidFieldList = styled('ul')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  color: ${({ color, ...restProps }) =>
    color ?? fromTheme('color_text')(restProps)};
  & > li {
    list-style-position: outside;
    margin-left: ${fromTheme('spacing_medium')};
    flex: 1 1 30%;
  }
`;
