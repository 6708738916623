export function getProviders(state={}) {
  return state.providers || {};
}

export function getProviderById(state, providerId) {
  return getProviders(state)[providerId];
}

export function getProvidersByIds(state, providerIds=[]) {
  return providerIds.map((providerId) => {
    return getProviderById(state, providerId);
  });
}