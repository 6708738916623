import { ProviderSortTypes } from '@kyruus/search-sdk';

type CareTeamProviderSort = Extract<
  ProviderSortTypes,
  'availability_soonest' | 'availability_density_best'
>;
/* 
Care team providers should always be sorted by availability
If a customer is configured for availability soonest it will be in the sort order array
Default to availability density best
*/
export const getCareTeamProvidersSort = (
  configuredSortOrder?: string
): CareTeamProviderSort => {
  const allSortOptions = configuredSortOrder?.split(',');
  if (allSortOptions?.includes('availability_soonest')) {
    return 'availability_soonest';
  }
  return 'availability_density_best';
};
