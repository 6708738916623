import {
  RESET_AVAILABILITY,
  SET_PATIENT_REL,
  SET_PURPOSE,
  SET_PROVIDER_ID,
  PURPOSES_LOADING_START,
  PURPOSES_LOADING_END,
  RECEIVE_PURPOSES,
  RECEIVE_PURPOSES_ERROR,
  RECEIVE_PURPOSES_SINGLE_PROVIDER,
  LOADING_SLOTS_START,
  RECEIVED_SLOTS,
  LOADING_SLOTS_ERROR
} from './actions';

import {
  ERROR_SLOT_COUNTS,
  UPDATE_PROVIDER_SLOT_COUNTS
} from '../search/actions';

import {
  SLOTS_LOADING_STATUS,
  SLOTS_SUCCESS_STATUS,
  SLOTS_ERROR_STATUS
} from '../../utils/constants';

export const DEFAULT_STATE = {
  patientRel: '',
  purpose: null,
  loading: false,
  purposesLoading: false,
  purposeOptions: {
    new: [],
    established: []
  },
  error: '',
  slotsByProviderId: {},
  slotsStatusByProviderId: {},
  hasFetchingSlotCountsFailed: false
};

export const getPurposesList = (facets) => {
  const { terms: purposesTerms = [] } =
    facets.find((purpose) => purpose.field === 'purpose') || {};

  const purposesList = purposesTerms.map((purpose) => {
    // There is always one visit method per purpose, but purpose.terms returns an array
    const visitMethodTerms =
      purpose.subfield === 'visit_method' ? purpose.terms : [];

    return {
      name: purpose.value,
      visit_method:
        visitMethodTerms && visitMethodTerms.length > 0
          ? visitMethodTerms[0].value
          : ''
    };
  });

  return purposesList.sort(({ name: a = '' }, { name: b = '' }) =>
    a.localeCompare(b)
  );
};

export default function availability(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PATIENT_REL:
      return {
        ...state,
        patientRel: action.patientRel
      };
    case SET_PURPOSE:
      return {
        ...state,
        purpose: action.purpose
      };
    case RESET_AVAILABILITY:
      return {
        ...state,
        ...DEFAULT_STATE
      };
    case SET_PROVIDER_ID:
      return {
        ...state,
        providerId: action.providerId
      };
    case PURPOSES_LOADING_START:
      return {
        ...state,
        loading: true,
        purposesLoading: true,
        error: ''
      };
    case PURPOSES_LOADING_END:
      return {
        ...state,
        loading: false,
        purposesLoading: false
      };
    case RECEIVE_PURPOSES:
      return {
        ...state,
        purposeOptions: {
          ...state.purposeOptions,
          [action.patientRel]: getPurposesList(action.purposes)
        }
      };
    case RECEIVE_PURPOSES_ERROR:
      return {
        ...state,
        loading: false,
        purposesLoading: false,
        error: action.error
      };
    case RECEIVE_PURPOSES_SINGLE_PROVIDER:
      return {
        ...state,
        loading: false,
        purposesLoading: false,
        availablePurposesByProviderId: {
          ...state.availablePurposesByProviderId,
          [action.providerId]: action.purposes
        }
      };
    case LOADING_SLOTS_START:
      return {
        ...state,
        slotsStatusByProviderId: {
          ...state.slotsStatusByProviderId,
          ...action.providerIds.reduce((acc, providerId) => {
            acc[providerId] = SLOTS_LOADING_STATUS;
            return acc;
          }, {})
        }
      };
    case RECEIVED_SLOTS:
      return {
        ...state,
        slotsByProviderId: {
          ...state.slotsByProviderId,
          ...action.slotsByProviderId
        },
        slotsStatusByProviderId: {
          ...state.slotsStatusByProviderId,
          ...action.providerIds.reduce((acc, providerId) => {
            acc[providerId] = SLOTS_SUCCESS_STATUS;
            return acc;
          }, {})
        }
      };
    case LOADING_SLOTS_ERROR:
      return {
        ...state,
        slotsStatusByProviderId: {
          ...state.slotsStatusByProviderId,
          ...action.providerIds.reduce((acc, providerId) => {
            acc[providerId] = SLOTS_ERROR_STATUS;
            return acc;
          }, {})
        }
      };
    case UPDATE_PROVIDER_SLOT_COUNTS:
      return {
        ...state,
        hasFetchingSlotCountsFailed: false
      };
    case ERROR_SLOT_COUNTS:
      return {
        ...state,
        hasFetchingSlotCountsFailed: true
      };
    default:
      return state;
  }
}
