import React from 'react';
import { Banner } from '@kyruus/banner';
import Cancel from '@kyruus/react-ikons/Cancel';
import { InvalidPatientDataMessage } from './InvalidPatientDataMessage';
import { BannerWrapper } from './styles';

const ErrorBanner = ({ type, invalidFields }) => {
  return (
    <Banner
      role="alert"
      aria-live="polite"
      type="alert"
      icon="!"
      action1Text={<Cancel />}
      action1="close"
    >
      <InvalidPatientDataMessage type={type} invalidFields={invalidFields} />
    </Banner>
  );
};

export const InvalidPatientDataBanner = ({
  crmState,
  isBookingEnabled = true,
  isReferralEnabled = true
}) => {
  return (
    <BannerWrapper className="container">
      {isBookingEnabled &&
      crmState.context.validationErrors.booking?.data.length > 0 ? (
        <ErrorBanner
          type="errorBooking"
          invalidFields={crmState.context.validationErrors.booking.data}
        />
      ) : null}
      {isReferralEnabled &&
      crmState.context.validationErrors.referral?.data.length > 0 ? (
        <ErrorBanner
          type="errorReferral"
          invalidFields={crmState.context.validationErrors.referral.data}
        />
      ) : null}
    </BannerWrapper>
  );
};
