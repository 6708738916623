import {
  // TODO: remove "_V8" actions when we fully transition to V9 search
  RECEIVE_PROFILE_V8,
  RECEIVE_PROFILE_ERROR_V8,
  RECEIVE_PROFILE,
  RECEIVE_PROFILE_ERROR
} from '../profiles';
import { RECEIVE_SEARCH, RECEIVE_SEARCH_ERROR } from '../search';
import { RECEIVE_SEARCH_V9 } from '../search-v9';

export const DEFAULT_STATE = false;

export default function errorReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case RECEIVE_PROFILE_V8:
    case RECEIVE_PROFILE:
    case RECEIVE_SEARCH:
    case RECEIVE_SEARCH_V9:
      return false;
    case RECEIVE_PROFILE_ERROR_V8:
    case RECEIVE_PROFILE_ERROR:
    case RECEIVE_SEARCH_ERROR:
      return true;
    default:
      return state;
  }
}
