import React from 'react';
import Banner from '@kyruus/banner';
import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';
import { defineMessages, FormattedMessage } from '@kyruus/intl';

// These messages should not contain variables, as these messages are also used server-side and will not be formatted.
const messages = defineMessages({
  errorbasicmessage: {
    id: 'error.basic.message',
    description: 'Error message when a page is unavailable',
    defaultMessage:
      'The page you are trying to reach is unavailable. Please try another search.'
  },
  errorBasicMessageNoSearch: {
    id: 'error.basic.message.no.seach',
    description:
      'Error message when a page is unavailable if search_match is not enabled',
    defaultMessage: 'The page you are trying to reach is unavailable.'
  },
  errorBasicMessageLocationsPage: {
    id: 'locations.error.basic.message',
    description:
      'Error message to display when a call to fetch providers fails on a locations page.',
    defaultMessage: "We're having trouble finding providers at this location."
  }
});

const Container = styled('div')`
  > div {
    border-radius: ${fromTheme('border_radius')};
    margin-top: ${fromTheme('spacing_large')};
  }
`;

export default function ErrorMessage({
  hasSearch = true,
  isLocationsPage
} = {}) {
  let formattedErrorMessage;
  if (isLocationsPage) {
    formattedErrorMessage = (
      <FormattedMessage {...messages.errorBasicMessageLocationsPage} />
    );
  } else {
    formattedErrorMessage = hasSearch ? (
      <FormattedMessage {...messages.errorbasicmessage} />
    ) : (
      <FormattedMessage {...messages.errorBasicMessageNoSearch} />
    );
  }
  return (
    <Container data-testid="ErrorMessage">
      <Banner type="alert" icon="!">
        {formattedErrorMessage}
      </Banner>
    </Container>
  );
}
