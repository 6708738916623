import { getVisibility } from '../../utils/search-common';

export function configSelector(state = {}) {
  const visibility = getVisibility(state.config);
  return { ...state.config, visibility } || {};
}

export function visibilitySelector(state = {}) {
  const config = configSelector(state);
  const visibility = getVisibility(config);
  return visibility;
}
