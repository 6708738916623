import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { COOKIE_NAMES } from './constants';
import { mergeSearchParamsAndNlpResult } from '../search/nlp';
import { isServerSide } from 'Common/utils/serverSide';
import { splitOnce } from 'Common/utils/splitOnce';
import { hashSearchServer, hashSearchClient } from './search-id-hashing';

// const SEARCH_ID_COOKIE_NAME = 'search_id';
const SEARCH_ID_COOKIE_TTL = 1; // 1 day

// delimiter between search id and the hashed search params in the token
export const SEARCH_ID_TOKEN_DELIMITER = '___';

export const generateNewSearchId = () => {
  return uuidv4();
};

/**
 * get hashed value from the search params object, and search results if we have them.
 * It's important to include the search results because the search params can be transformed by the
 * NLP fields in the results
 */
export const getHashedSearch = async (searchParams, searchResults = null) => {
  const nlpData = searchResults?.messages?.nlp_actions;
  const mergedSearchParams = mergeSearchParamsAndNlpResult(
    searchParams,
    nlpData
  );
  const hashedSearch = await hashSearch(mergedSearchParams);
  return hashedSearch;
};

/**
 * get stored data for existing search id and the associated hashed search
 * @param {*} param0
 * @returns
 */
export const getExistingSearchData = async ({ req } = {}) => {
  let searchIdTokenValue;

  if (isServerSide()) {
    if (req) {
      searchIdTokenValue = await req.getSearchIdToken();
    } else {
      throw new Error(
        'getExistingSearchData must be called with a req object on the server'
      );
    }
  } else {
    searchIdTokenValue = Cookies.get(COOKIE_NAMES.SEARCH_ID_TOKEN);
  }

  if (!searchIdTokenValue) {
    return { searchId: null, searchParamsHash: null };
  }
  const [searchId, searchParamsHash] = splitOnce(
    searchIdTokenValue,
    SEARCH_ID_TOKEN_DELIMITER
  );
  return { searchId, searchParamsHash };
};

/**
 * save the given searchId and hashedParams to the search id cookie
 * @param {*} param0
 */
export const saveSearchData = ({ searchId, hashedParams, req }) => {
  const value = `${searchId}${SEARCH_ID_TOKEN_DELIMITER}${hashedParams}`;

  if (isServerSide()) {
    // server side
    if (req) {
      req.setSearchIdToken(value);
    } else {
      throw new Error('req object required to set search id on server');
    }
  } else {
    // client side
    Cookies.set(COOKIE_NAMES.SEARCH_ID_TOKEN, value, {
      expires: SEARCH_ID_COOKIE_TTL,
      partitioned: true,
      secure: true,
      sameSite: 'None'
    });
  }
};

/**
 * Save current data of the search to compare against in subsequent searches.
 * Store it in LS to persist across pages and uncouple from general search handling
 *
 * This function is to be called before the search is actually executed, so that we can
 * create a new search id and send it along with the search if we need to
 * @param {} searchSummary
 */
export const handleSearchIdTracking = async ({ searchParams, req }) => {
  // get existing search data
  const { searchParamsHash: previousSearchParamsHash } =
    await getExistingSearchData({
      req
    });

  const newSearchParamsHash = await getHashedSearch(searchParams);

  // if the search params have changed, we need a new search id
  if (newSearchParamsHash === previousSearchParamsHash) {
    // if new search params hash is the same as the previous search params hash,
    // we don't need to generate a new id so can exit here
    return;
  }

  const newSearchId = generateNewSearchId();

  // this block is only for debug messages on the server
  if (req) {
    const appSettings = await req.getAppSettings();
    if (appSettings.DEBUG) {
      req
        .getLog()
        .debug(
          `A new search id will be generated for this search: ${newSearchId}`,
          searchParams
        );
    }
  }

  /**
   * Save data and token for this new search, which is unique from the previous search
   * based on the fields that we are considering a new search
   */
  saveSearchData({
    searchId: newSearchId,
    hashedParams: newSearchParamsHash,
    req
  });
};

/**
 * need to track the search results that we got back for a search id,
 * and incorporate any NLP data that could have transformed the original params,
 * so that subsequent searches can be compared against this data
 * @param {} param0
 */
export const saveSearchIdResultsData = async ({
  req,
  searchParams,
  searchResults
}) => {
  // hash of the search params that just completed and we got results for
  const hashedSearchWithResults = await getHashedSearch(
    searchParams,
    searchResults
  );
  // existing stored search id,
  const { searchId, searchParamsHash } = await getExistingSearchData({
    req
  });

  if (hashedSearchWithResults !== searchParamsHash) {
    // if hashes are different, that just means the nlp / results data transformed
    // the original params. in that case we need to save it
    saveSearchData({
      searchId,
      hashedParams: hashedSearchWithResults,
      req
    });
  }
};

export const hashSearch = async (searchParams) => {
  if (isServerSide()) {
    return await hashSearchServer(searchParams);
  }
  return await hashSearchClient(searchParams);
};
