import { isEmbeddedInFrame } from './iframe';
import { Base64 } from 'js-base64';

export const CERNER_BCS_TOKEN_PARAM = 'bcs_token';
export const CERNER_PID_TYPE = 'cerner_federated_id';
export const CERNER_BCS_TOKEN_QUERY_PARAM = 'bcs_token';

export function isCCF() {
  return (
    typeof window !== 'undefined' &&
    isEmbeddedInFrame() &&
    Object.hasOwnProperty.call(window, '$HL')
  );
}

export function scrollToTopCCF() {
  // Scroll to the top of the page when PMC is embedded inside
  // the Cerner Healthelife patient portal.
  // This is necessary because the position of the modal is relative to the
  // viewport of PMC instead of the frame. As a result, if PMC is rendered
  // inside of a non-scrollable iframe so the user scrolls down in the outer
  // context, PMC will display the frame at the top of the page like it's
  // supposed to, except that will result in the modal appearing offscreen
  // because it's basing the position of the modal on the top of the PMC page
  // inside of the top of the outer context's viewport.
  // This uses the Cerner-specific scroll function because that's the only
  // way for code inside the frame to force the parent context to do something.
  // See https://stackoverflow.com/a/36412029
  if (isCCF()) {
    window.$HL.App.scrollIntoView();
  }
}

/**
 * Given a cernerBcsToken, returns the FPA (Federated Principal Alias) associated with the token
 * @param {string} cernerBcsToken such as that returned from getCernerBcsToken
 * @returns {string} a cernerFPA, if the cernerBcsToken is parseable
 * @returns {null} if the cernerBcsToken could not be parsed to get a cernerFPA
 */
export function getCernerFpa(cernerBcsToken) {
  try {
    const [, payload] = cernerBcsToken.split('.');
    // using Base64 library to faciliate decoding tokens containing utf-8
    return JSON.parse(Base64.decode(payload)).sub;
  } catch (e) {
    return null;
  }
}
