/*
 * These constants are the same as the ones for z-indexes in _varaibles.sass from the old style_guide package.
 * They are meant to help us keep consistent z-indexes as we shift away from SASS towards Emotion.
 */

// List of z-indexes should be kept in ascending order
const zIndex = {
  navbar: 1000,
  dropdown: 1000,
  navbarFixed: 1030,
  modal: 1040,
  popover: 1060,
  tooltip: 1070
};

export default zIndex;
