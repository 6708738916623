import {
  // location actions
  REQUEST_MAP_LOCATIONS,
  RECEIVE_MAP_LOCATIONS,
  RECEIVE_MAP_LOCATIONS_ERROR,

  // providers actions
  REQUEST_MAP_PROVIDERS,
  RECEIVE_MAP_PROVIDERS,
  RECEIVE_MAP_PROVIDERS_ERROR,

  // providers by location (mloc)
  REQUEST_MAP_PROVIDERS_BY_LOCATION,
  RECEIVE_MAP_PROVIDERS_BY_LOCATION,
  RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR
} from './actions';

import {
  STATE_ERROR,
  STATE_LOADING,
  STATE_PENDING,
  STATE_READY
} from './states';

const DEFAULT_LOCATIONS = {
  state: STATE_PENDING
};
const DEFAULT_PROVIDERS = {
  state: STATE_PENDING
};
const DEFAULT_PROVIDERS_BY_LOCATION = {};

const DEFAULT_STATE = Object.freeze({
  locations: DEFAULT_LOCATIONS,
  providers: DEFAULT_PROVIDERS,
  providersByLocation: DEFAULT_PROVIDERS_BY_LOCATION
});

export default function locationsReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REQUEST_MAP_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          state: STATE_LOADING
        }
      };
    case REQUEST_MAP_PROVIDERS:
      return {
        ...state,
        providers: {
          ...state.providers,
          state: STATE_LOADING
        }
      };
    case RECEIVE_MAP_LOCATIONS:
      return {
        ...state,
        locations: {
          state: STATE_READY,
          ...action.payload
        }
      };
    case RECEIVE_MAP_PROVIDERS:
      return {
        ...state,
        providers: {
          state: STATE_READY,
          ...action.payload
        }
      };
    case RECEIVE_MAP_LOCATIONS_ERROR:
      return {
        ...state,
        locations: {
          state: STATE_ERROR,
          error: action.payload
        }
      };
    case RECEIVE_MAP_PROVIDERS_ERROR:
      return {
        ...state,
        providers: {
          state: STATE_ERROR,
          error: action.payload
        }
      };
    case REQUEST_MAP_PROVIDERS_BY_LOCATION:
      return {
        ...state,
        providersByLocation: {
          ...state.providersByLocation,
          [action.payload.locationId]: {
            state: STATE_LOADING
          }
        }
      };
    case RECEIVE_MAP_PROVIDERS_BY_LOCATION:
      return {
        ...state,
        providersByLocation: {
          ...state.providersByLocation,
          [action.payload.locationId]: {
            state: STATE_READY,
            ...action.payload.responseData
          }
        }
      };
    case RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR:
      return {
        ...state,
        providersByLocation: {
          ...state.providersByLocation,
          [action.payload.locationId]: {
            state: STATE_ERROR,
            error: action.payload.error
          }
        }
      };
    default:
      return state;
  }
}
