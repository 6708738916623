import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Button from '@kyruus/button';

export const EditorWindow = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  top: 24px;
  left: 24px;
  width: 700px;
  height: 700px;
  border: 3px solid rgba(0, 0, 0);
  border-radius: 3px;
  padding: 10px;
  background: #fff;
  z-index: 9001;
  resize: both;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: all 0.25s;
  transition-property: background, opacity, max-height;
  &.minimized {
    opacity: 0.5;
    width: 300px !important;
    height: 60px !important;
    resize: none;
    transition-property: background, width, height, opacity, max-height;
    .content {
      display: none !important;
    }
  }
  h4 {
    font-weight: bold;
    margin-bottom: 14px;
    font-size: 1.3em;
  }
`;

export const ControlButton = styled.button`
  text-align: center;
  padding: 2px;
  vertical-align: text-top;
  appearance: none;
  width: 36px;
  height: 36px;
  border: solid 1px #ccc;
  border-radius: 3px;
  &:hover {
    background-color: #ccc;
  }
`;

export const Handle = styled.div`
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 48px;
  row-gap: 8px;
  & > h2 {
    margin: 0;
  }
  & > span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    color: #667382;
    & > svg {
      fill: #2a3d51;
      height: 16px;
    }
  }
  cursor: grab;
  margin: -10px;
  padding: 10px;
`;

export const Content = styled.div`
  height: 95%;
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const ConfigActiveBanner = styled.div`
  position: fixed;
  background: red;
  color: #fff;
  top: 0;
  left: 0;
  padding: 6px;
  font-weight: bold;
  border-radius: 2px;
  z-index: 9002;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

export const GridMenu = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  padding: 32px;
  color: #2a3d51;
  & > button {
    height: 100px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 8px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:focus {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
    &:is(:hover, :active, :focus) {
      background-color: #e9ebed;
    }
    & > svg {
      fill: currentColor;
      height: 50px;
    }
  }
`;

export const BackButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const Toolbar = styled('div')`
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 1000;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 4px;
  border-bottom: solid 1px #cdcdcd;
  margin-bottom: 6px;
  padding-bottom: 6px;
  & > button:first-of-type {
    margin-right: 48px;
  }
`;

export const TitleBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
`;

export const TitleBarButtonGroup = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
`;

const rotation = keyframes`
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
`;

export const Loader = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #667382;
  font-size: 30px;
  & > svg {
    height: 50px;
    fill: currentColor;
    display: inline-block;
    animation: ${rotation} 2s infinite linear;
  }
`;

export const Rotate = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: ${rotation} 2s infinite linear;
`;

export const MockingEnabledBanner = styled('div')`
  padding: 6px;
  color: #fff;
  background-color: #0c0;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  z-index: 9001;
`;

export const EditorTitle = styled('h2')`
  font-weight: bold;
`;

export const SectionTitle = styled('h3')`
  margin-bottom: 12px;
  font-weight: bold;
`;
