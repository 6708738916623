import React, { useState, useEffect } from 'react';
import { ConfigActiveBanner } from './styles';

export default function CustomConfigActiveBanner({ authenticatedUserInfo }) {
  const [bannerShouldRender, setBannerShouldRender] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.href.search);

    if (
      window.document.cookie.indexOf('admin_editor_config_id') > -1 ||
      queryParams.get('admin_editor_config_id')
    ) {
      setBannerShouldRender(true);
    }
  }, []);

  const handleReset = (e) => {
    e.preventDefault();
    window.document.cookie =
      'admin_editor_config_id=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    window.location.href = '/';
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (window.admin && window.admin.enableEditor) {
      window.admin.enableEditor();
    }
  };

  const buttons = [];
  buttons.push(
    <a
      title="Clear the custom configuration and go back to viewing the live configuration."
      onClick={handleReset}
    >
      Reset
    </a>
  );

  if (authenticatedUserInfo?.permissions?.includes('pmc_editor:write')) {
    buttons.push(
      <a title="Open the PMC Live editor" onClick={handleEdit}>
        Edit
      </a>
    );
  }

  if (bannerShouldRender) {
    return (
      <ConfigActiveBanner>
        A preview configuration is active.{' '}
        {buttons.map((button, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx > 0 ? ' or ' : ''}
              {button}
            </React.Fragment>
          );
        })}
      </ConfigActiveBanner>
    );
  }

  return <span />;
}
