import React from 'react';

const UserInfoContext = React.createContext();

export const AuthenticatedUserInfoProvider = ({ userInfo, ...props }) => {
  const user = {
    email: userInfo?.userEmail,
    hasPmaAccess: userInfo?.permissions?.includes('pma:access') ? true : false,
    permissions: userInfo?.permissions
  };

  return <UserInfoContext.Provider value={user} {...props} />;
};

export const useAuthenticatedUserInfo = () => {
  const context = React.useContext(UserInfoContext);
  if (context == null) {
    throw new Error(
      `userInfoContext must be used within an AuthenticatedUserInfoProvider`
    );
  }
  return context;
};
