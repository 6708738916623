import React, { ComponentProps } from 'react';
import { IntlProvider } from '@kyruus/intl';
import compiledMessages from './compiled.json';

/**
 * An `IntlProvider` that allows for dynamic messages to be passed in.
 *
 * @param props Props for the IntlProvider
 * @returns `IntlProvider` with precompiled messages and any additional messages passed in
 */
export const DynamicIntlProvider = ({
  children,
  messages,
  ...rest
}: ComponentProps<typeof IntlProvider>) => {
  return (
    <IntlProvider messages={{ ...compiledMessages, ...messages }} {...rest}>
      {children}
    </IntlProvider>
  );
};
