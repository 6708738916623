import axios from 'axios';
import { getBaseCookieParams } from './tracking-tokens';

// rwg token, per https://developers.google.com/actions-center/verticals/local-services/redirect/integration-steps/conversion-tracking
export const RWG_TOKEN = {
  queryParamName: 'rwg_token',
  cookieName: '_rwg_token'
};

export const RWG_TOKEN_MERCHANT = {
  cookieName: '_rwg_token_merchant_id'
};

export async function trackRwgConversionEvent(req) {
  const appSettings = await req.getAppSettings();
  const {
    DEBUG,
    GOOGLE_RWG_CONVERSION_ENDPOINT,
    GOOGLE_RWG_CONVERSION_PARTNER_ID
  } = appSettings;

  const { rwgToken, merchantId } =
    await req.getGoogleConversionRwgTokenValues();

  if (
    !rwgToken ||
    !GOOGLE_RWG_CONVERSION_ENDPOINT ||
    !GOOGLE_RWG_CONVERSION_PARTNER_ID
  ) {
    return;
  }

  const hasMerchantChanged = `${req.body.provider_id}` !== `${merchantId}`;

  const conversionData = {
    conversion_partner_id: GOOGLE_RWG_CONVERSION_PARTNER_ID,
    rwg_token: rwgToken,
    merchant_changed: hasMerchantChanged ? '1' : '2'
  };

  if (DEBUG) {
    req
      .getLog()
      .debug(
        `RWG conversion event: ${JSON.stringify(conversionData, null, 2)}`
      );
  }

  await axios.post(
    GOOGLE_RWG_CONVERSION_ENDPOINT,
    JSON.stringify(conversionData),
    {
      headers: {
        'Content-Type': 'text/plain'
      }
    }
  );
}

export async function getRwgTokenValues(req) {
  let rwgToken; // corresponds to initial query param that user lands on DBW with, which we then save
  let merchantId; //basically corresponds to provider ID

  if (req.query[RWG_TOKEN.queryParamName]) {
    rwgToken = req.query[RWG_TOKEN.queryParamName];
    // only pull merchant Id if rwg_token comes from the URL.
    // Merchant ID should come from the route itself, as all links with rwg_token should be direct provider links to direct book or profile.
    // If we _do_ get a link to a non-provider-specific page with an rwg_token, that implies the link is not specific to a provider anyway
    // in which case we don't need merchant Id.
    merchantId = req.params.providerId;
  } else if (req.cookies[RWG_TOKEN.cookieName]) {
    rwgToken = req.cookies[RWG_TOKEN.cookieName];
    if (req.cookies[RWG_TOKEN_MERCHANT.cookieName]) {
      merchantId = req.cookies[RWG_TOKEN_MERCHANT.cookieName];
    }
  }

  return {
    rwgToken,
    merchantId
  };
}

// saved rwg cookies to session
export async function saveRwgTokenValues(req, res) {
  const appSettings = await req.getAppSettings();
  const baseCookieParams = await getBaseCookieParams(req);
  const { rwgToken, merchantId } =
    await req.getGoogleConversionRwgTokenValues();

  // only check rwgToken at the top level because merchantId isn't relevant without it
  if (rwgToken) {
    res.cookie(RWG_TOKEN.cookieName, rwgToken, {
      ...baseCookieParams,
      maxAge: appSettings['SECONDS_UNTIL_RWG_TOKEN_EXPIRES'] * 1000
    });

    if (merchantId) {
      res.cookie(RWG_TOKEN_MERCHANT.cookieName, merchantId, {
        ...baseCookieParams,
        maxAge: appSettings['SECONDS_UNTIL_RWG_TOKEN_EXPIRES'] * 1000
      });
    }
  }
}
