export const SET_BCS_TOKEN = 'SET_BCS_TOKEN';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_SEARCH_SHUFFLE_TOKEN = 'SET_SEARCH_SHUFFLE_TOKEN';

import { type TokensStore } from './types';

export function setBcsToken(token: string) {
  return {
    type: SET_BCS_TOKEN,
    payload: token
  };
}

export function setSearchShuffleToken(token: string) {
  return {
    type: SET_SEARCH_SHUFFLE_TOKEN,
    payload: token
  };
}

export function setTokens(tokens: TokensStore) {
  return {
    type: SET_TOKENS,
    payload: tokens
  };
}
