import axios from 'axios';
import { Search } from '@kyruus/search-sdk';
import { transformFilterUrlParamsForSearchSdk } from '../search-v9/actions/utils';
import { productNameSelector } from '../product-name';
import { getDefaultCustomerSort } from '../../utils/search-common';

export const REQUEST_MAP_LOCATIONS = 'REQUEST_MAP_LOCATIONS';
export const RECEIVE_MAP_LOCATIONS_V9 = 'RECEIVE_MAP_LOCATIONS_V9';
export const RECEIVE_MAP_LOCATIONS_ERROR = 'RECEIVE_MAP_LOCATIONS_ERROR';

export const REQUEST_MAP_PROVIDERS = 'REQUEST_MAP_PROVIDERS';
export const RECEIVE_MAP_PROVIDERS_V9 = 'RECEIVE_MAP_PROVIDERS_V9';
export const RECEIVE_MAP_PROVIDERS_ERROR = 'RECEIVE_MAP_PROVIDERS_ERROR';

export const REQUEST_MAP_PROVIDERS_BY_LOCATION =
  'REQUEST_MAP_PROVIDERS_BY_LOCATION';
export const RECEIVE_MAP_PROVIDERS_BY_LOCATION_V9 =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION_V9';
export const RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR';

const PROVIDER_RESULTS_PER_PAGE = 10;

const MAX_BADGE_FIELDS = 3;

/*
  Utility Functions
*/

/**
 * Get facet fields defined in config for use in searches
 * @param {*} config The customer config object containing the customers configured facets
 * @returns An array of all facet fields
 */
function getFacets(config) {
  return (config.facets_v9 || []).map((facet) => [facet.field]);
}

/**
 * Get common params used by `fetchMapProviders*` functions
 * @param {Object} param Object containing the config, customerCoder, and tokens
 * @returns An Object containing common search params
 */
const getCommonMapProvidersParams = ({ config, customerCode, tokens }) => ({
  context: config.index || customerCode,
  tracking_token: tokens?.consumerTrackingToken,
  search_token: tokens?.searchToken,
  user_id: tokens?.anonymousUserId,
  user_token: tokens?.userToken
});

/**
 * Function to normalize some search paramaters as integers
 * @param {Object} params The params to normalize
 * @returns The params with page and distance parsed as integers
 */
function normalizeSearchParams(params) {
  const newParams = { ...params };
  if ('page' in newParams) {
    newParams.page = parseInt(newParams.page, 10) || 1;
  }
  if ('distance' in newParams) {
    newParams.distance = parseInt(newParams.distance, 10);
  }
  return newParams;
}

/**
 * Function to get summary_data, logo, and badge fields defined in the config so that we can make
 * sure to request them from the search service
 * @param {Object} config The customer config object
 * @returns An array with all customer defined summary_data, logo, and badge fields
 */
export function getConfiguredSearchFields(config) {
  const { provider_v9, logos_v9, search_results_page_v9 } = config;
  let fields = [];

  // Collect "badge" fields from "config.provider.badge_data"
  if (provider_v9 && Array.isArray(provider_v9.badge_data)) {
    fields = [
      ...fields,
      ...provider_v9.badge_data
        .map((badge) => badge.field)
        .splice(0, MAX_BADGE_FIELDS)
    ];
  } else {
    // if badge fields are not in config, use "default" badge fields
    fields = [...fields, 'accepting_new_patients'];
  }

  // Collect "summary" fields from "config.search_results_page"
  if (
    search_results_page_v9 &&
    Array.isArray(search_results_page_v9.summary_data)
  ) {
    fields = [
      ...fields,
      ...search_results_page_v9.summary_data.map((fieldData) => fieldData.field)
    ];
  } else {
    // if summary_data is not in config, use "default" summary config fields
    fields = [...fields, 'specialties', 'hospital_affiliations'];
  }

  // Collect "logos" fields from "config.logos"
  if (logos_v9 && Array.isArray(logos_v9)) {
    fields = [...fields, ...logos_v9.map((logo) => logo.field)];
  }

  // Collect all tab fields needed to display provider tab data
  if (Array.isArray(config?.provider_tile?.provider_card_tabs?.tabs)) {
    const tabFields = config?.provider_tile?.provider_card_tabs?.tabs.reduce(
      (acc, tab) => {
        if (tab?.notes_fields != null) {
          tab.notes_fields.forEach(({ path } = {}) => acc.add(path));
        }
        return acc;
      },
      new Set([
        'rmc_scheduling_instructions',
        'insurance_accepted',
        'practice_groups',
        'training'
      ])
    );
    fields = [...fields, ...tabFields];
  }

  return fields;
}

/*
  Action Functions
*/

export function requestMapLocations() {
  return {
    type: REQUEST_MAP_LOCATIONS
  };
}

export function receiveMapLocationsV9(responseData) {
  return {
    type: RECEIVE_MAP_LOCATIONS_V9,
    payload: responseData
  };
}

export function receiveMapLocationsError(error) {
  return {
    type: RECEIVE_MAP_LOCATIONS_ERROR,
    payload: error
  };
}

export function requestMapProviders() {
  return {
    type: REQUEST_MAP_PROVIDERS
  };
}

export function receiveMapProvidersV9(responseData) {
  return {
    type: RECEIVE_MAP_PROVIDERS_V9,
    payload: responseData
  };
}

export function receiveMapProvidersError(error) {
  return {
    type: RECEIVE_MAP_PROVIDERS_ERROR,
    payload: error
  };
}

export function requestMapProvidersByLocation(locationId) {
  return {
    type: REQUEST_MAP_PROVIDERS_BY_LOCATION,
    payload: {
      locationId
    }
  };
}

export function receiveMapProvidersByLocationV9(locationId, responseData) {
  return {
    type: RECEIVE_MAP_PROVIDERS_BY_LOCATION_V9,
    payload: {
      locationId,
      responseData
    }
  };
}

export function receiveMapProvidersByLocationError(locationId, error) {
  return {
    type: RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR,
    payload: {
      locationId,
      error
    }
  };
}

/**
 * Filter out certain filters from the filters array that we don't want to include
 * in the call to get providers for a location
 * @param {Array} filters
 * @returns
 */
export function filterFilterParamsForProvidersByLocation(filters) {
  return (filters || []).filter((filter) => {
    const [name] = filter.split(':');
    // filter out `locations.name` filters because they contradict with `mloc id` filters
    // in the search
    if (name === 'locations.name') {
      return false;
    }
    return true;
  });
}
/*
  Thunk functions
*/

export function fetchMapLocationsV9(params) {
  return async (dispatch, getState) => {
    // Dispatch request
    dispatch(requestMapLocations());

    // Extract values from state
    const state = getState();
    const { config, customerCode, tokens } = state;

    // Construct params
    const searchParams = normalizeSearchParams({
      // @TODO: refine list of fields after we've built map features
      provider_fields: ['ALL'].join(','),
      ...getCommonMapProvidersParams({ config, customerCode, tokens }),
      ...params
    });

    try {
      const proxySearchResponse = await axios.post(
        `/api/searchservice-v9/${encodeURIComponent(customerCode)}/locations`,
        // send data in POST body:
        // location ids may be too many to be transmitted in a URL
        JSON.stringify(searchParams),
        {
          withCredentials: true,
          headers: {
            'X-Consumer-Groups': customerCode,
            'Content-Type': 'application/json'
          }
        }
      );
      const proxySearchData = proxySearchResponse.data;
      dispatch(receiveMapLocationsV9(proxySearchData));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return proxySearchData;
    } catch (error) {
      dispatch(receiveMapLocationsError(error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}

export function fetchMapProvidersV9(params) {
  return async (dispatch, getState) => {
    // Dispatch Request
    dispatch(requestMapProviders());

    // Extract values from state
    const state = getState();
    const { config, customerCode, tokens } = state;

    // Client setup
    const searchClient = new Search({
      customerCode,
      baseURL: '/api/searchservice-v9',
      productName: productNameSelector(state)
    });

    // Construct params
    const fullParams = normalizeSearchParams({
      provider_fields: [
        // we only need to read facets to get the list of locations to fetch subsequently,
        // so no fields are required at this time
        '-ALL'
      ].join(','),
      availability_format: 1,
      // shuffle_seed: Cookies.get('consumer_tracking_token'), // doesn't matter
      per_page: PROVIDER_RESULTS_PER_PAGE,
      // append id location ids facet to the facets from the config
      facet: [
        ['locations.primary_marketable_location_id'],
        ...getFacets(config)
      ],
      ...getCommonMapProvidersParams({ config, customerCode, tokens }),
      ...params,
      sort: params.sort || getDefaultCustomerSort(config)
    });

    fullParams.filter = transformFilterUrlParamsForSearchSdk(fullParams.filter);

    try {
      const searchResponse = await searchClient.getProviders(fullParams);
      const searchData = searchResponse.data;
      dispatch(receiveMapProvidersV9(searchData));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return searchData;
    } catch (error) {
      dispatch(receiveMapProvidersError(error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}

export function fetchMapProvidersByLocationV9(locationId, params) {
  return async (dispatch, getState) => {
    // Dispatch Request
    dispatch(requestMapProvidersByLocation(locationId));

    // Extract values from state
    const state = getState();
    const { config, customerCode, tokens } = state;

    // Client setup
    const searchClient = new Search({
      customerCode,
      baseURL: '/api/searchservice-v9',
      productName: productNameSelector(state)
    });

    // create final array of filter params
    // if receive as array then spread the param values
    // if receive as string then wrap in [] to make array
    const manageFilter = (filter = []) => {
      return Array.isArray(filter) ? [...filter] : [filter];
    };

    // Construct params
    const fullParams = normalizeSearchParams({
      provider_fields: [
        '-ALL',
        'appointment_ehr_purposes',
        'availability_density_best',
        'book_online_url',
        'contacts',
        'degrees',
        'entity_type',
        'external_id',
        'gender',
        'id',
        'image_url',
        'locations',
        'name',
        'provider_type',
        'provider_videos',
        'request_appointment_url',
        'reviews',
        'virtual_care_url',
        ...getConfiguredSearchFields(config)
      ].join(','),
      availability_format: 1,
      shuffle_seed: tokens?.searchShuffleToken,
      per_page: PROVIDER_RESULTS_PER_PAGE,
      // append id location ids facet to the facets from the config
      facet: [['provider.id'], ...getFacets(config)],
      ...getCommonMapProvidersParams({ config, customerCode, tokens }),
      // typeahead_categories: '', // don't need to send this?
      // order matters. `params` needs to come before `filter` in this object
      // copied from the fields we fetch for providers on the list page in the v1 api
      ...params,
      filter: [
        ...filterFilterParamsForProvidersByLocation(
          manageFilter(params.filter)
        ),
        `locations.primary_marketable_location_id:${locationId}`
      ],
      sort: params.sort || getDefaultCustomerSort(config)
    });

    fullParams.filter = transformFilterUrlParamsForSearchSdk(fullParams.filter);

    // append options `components`
    if (config?.search_params?.geocoding?.components?.administrative_area) {
      fullParams.components = `administrative_area:${config.search_params.geocoding.components.administrative_area}`;
    }

    try {
      const searchResponse = await searchClient.getProviders(fullParams);
      const searchData = searchResponse.data;
      dispatch(receiveMapProvidersByLocationV9(locationId, searchData));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return searchData;
    } catch (error) {
      dispatch(receiveMapProvidersByLocationError(locationId, error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}
